import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { APP_CONFIG } from '../environment/Env';
import { HTTPService } from '../service/http-service';
import { SharedService } from '../service/shared-service';


@Component({
  selector: 'password',
  templateUrl: './password.component.html',
  styleUrls: [ './recovery.component.css' ]
})
export class PassrordComponent {
  
  message: string = "";
  isValidToken: boolean = false;
  userId: string = "";
  role: string = "";

  constructor(private formBuilder: FormBuilder, private _service: SharedService, private _http: HTTPService, private _route: ActivatedRoute) {}

  ngOnInit() {
    this.message = "";
    this._route.queryParams.subscribe(params=>{
      this.getTokenDetails(params["token"]);
    });
  }

  getTokenDetails(token) {
    this._service.openSpinner();
    let params = [];
    params[0] = {};
    params[0]["key"] = "token";
    params[0]["value"] = token;
    this._http.get(APP_CONFIG.adminAPIPath+"/public/recovery",params).subscribe(data=>{
      if(data["message"] == "SUCCESS") {
        this.isValidToken = true;
        this.userId = data["userId"];
        this.role = data["role"];
        this.recoveryForm.get("email").setValue(this.userId);
      }
      else {
        this.message = data["message"];
      }
      this._service.closeSpinner();
    });

  }

  recoveryForm: FormGroup = this.formBuilder.group({
    email: [,{validators: [], updateOn: "change",}],
    password: [, { validators: [Validators.required, Validators.minLength(8)], updateOn: "change" }],
    confirmPassword: [, { validators: [Validators.required, Validators.minLength(8)], updateOn: "change" }]
  });

  updatePassword() {
    this.message = "";
    this._service.openSpinner();
    let input = {};
    input["userId"] = this.userId;
    input["role"] = this.role;
    input["password"] = this.recoveryForm.get("password").value;
    input["confirmPassword"] = this.recoveryForm.get("confirmPassword").value;

    this._http.post(APP_CONFIG.adminAPIPath+"/public/password",input).subscribe(data=>{
      this.message = data["message"];
      this._service.closeSpinner();
    });
  }

}