<br/><br/>
<div class="footer-container">
    <div class="footer-menu">
        &copy; {{currentYear}} Drug Quester Private Ltd
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a routerLink="/tnc" routerLinkActive="active">Terms & Conditions</a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a routerLink="/privacy" routerLinkActive="active">Privacy and Cookies</a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a routerLink="/blog" routerLinkActive="active">Blog</a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a routerLink="/career" routerLinkActive="active">Careers</a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a routerLink="/contact" routerLinkActive="active">Contact Us</a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a routerLink="/userGuide" routerLinkActive="active">User Guide</a>
    </div>
    <div style="font-size: 12px; width: 100%;">
        <a href="https://www.linkedin.com/company/drug-quester/about/" target="new" class="fa fa-linkedin fas"></a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="https://www.facebook.com/Drug-Quester-Private-Limited-103822422243315" target="new" class="fa fa-facebook fas"></a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="https://twitter.com/ltd_drug" target="new" class="fa fa-twitter fas"></a>
        <!--&nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="#" target="new" class="fa fa-google fas"></a>-->
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="https://www.youtube.com/channel/UCQxntkV-rc0iMR2njCVTfwA" target="new" class="fa fa-youtube fas"></a>
        &nbsp;&nbsp;|&nbsp;&nbsp;
        <a href="https://www.instagram.com/drugquester/" target="new" class="fa fa-instagram fas"></a>
    </div>
</div>