<div class="popup">
    <div class="content-box" style="background-color: #FFFFFF; margin-top: 10%;">
        <form class="example-form" [formGroup]="contactForm" (ngSubmit)="submit()" #formDirective="ngForm">
            <table align="center" cellspacing="10px">
                <tr>
                    <td>
                        <mat-form-field class="form-field-full-width">
                            <mat-label>Name</mat-label>
                            <input required matInput formControlName="name" placeholder="">
                            <mat-error *ngIf="contactForm.get('name').hasError('required')">
                                Name is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="form-field-full-width">
                            <mat-label>Email</mat-label>
                            <input type="email" required matInput formControlName="email" placeholder="Ex. pat@example.com">
                            <mat-error *ngIf="contactForm.get('email').hasError('email') && !contactForm.get('email').hasError('required')">
                                Please enter a valid email address
                            </mat-error>
                            <mat-error *ngIf="contactForm.get('email').hasError('required')">
                                Email is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </td>
                </tr>
                <tr *ngIf="showQuoteRequestPanel">
                    <td colspan="2">
                        <mat-form-field style="width: 100%;">
                            <mat-label>Country</mat-label>
                            <mat-select required formControlName="country" [(value)]="countrycode">
                                <mat-option *ngFor="let country of countries" [value]="country.code">
                                    {{country.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
                <tr *ngIf="showQuoteRequestPanel">
                    <td colspan="2">
                        <mat-form-field style="width: 100%;">
                            <mat-label>Contact No</mat-label>
                            <span matPrefix>{{countrycode}} &nbsp;</span>
                            <input required type="tel" matInput formControlName="contact" placeholder="555-555-1234">                    
                        </mat-form-field>            
                    </td>
                </tr>
                <tr *ngIf="showQuoteRequestPanel">
                    <td colspan="2">
                        <mat-form-field style="width: 100%;">
                            <mat-label>Company</mat-label>
                            <input required matInput formControlName="company" placeholder="555-555-1234">                    
                        </mat-form-field>            
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        <mat-form-field style="width: 100%;">
                            <mat-label>Message</mat-label>
                            <textarea maxlength="200" required matInput formControlName="text" placeholder="" #text></textarea>
                            <mat-error *ngIf="contactForm.get('text').hasError('required')">
                                Message Text is <strong>required</strong>
                            </mat-error>
                            <mat-hint align="end">{{text.value.length}} / 200</mat-hint>
                        </mat-form-field>
                    </td> 
                </tr>
                <tr *ngIf="showForwardRequestPanel">
                    <td>
                        <mat-form-field class="form-field-full-width">
                            <mat-label>Recipient Name</mat-label>
                            <input required matInput formControlName="frndname" placeholder="">
                            <mat-error *ngIf="contactForm.get('frndname').hasError('required')">
                                Recipient Name is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="form-field-full-width">
                            <mat-label>Recipient Email</mat-label>
                            <input type="email" required matInput formControlName="frndemail" placeholder="Ex. pat@example.com">
                            <mat-error *ngIf="contactForm.get('frndemail').hasError('email') && !contactForm.get('frndemail').hasError('required')">
                                Please enter a valid Recipient email address
                            </mat-error>
                            <mat-error *ngIf="contactForm.get('frndemail').hasError('required')">
                                Recipient Email is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td align="center" colspan="2">
                        <button 
                            mat-raised-button 
                            color="primary" 
                            [disabled]="contactForm.invalid"
                            type="submit"
                        >
                            Submit
                        </button>&nbsp;&nbsp;      
                        <button mat-raised-button color="warn"  (click)="cancel()">Close</button>
                    </td>
                </tr>
            </table>
        </form>
        <h3 align="center" *ngIf="message != ''">{{message}}</h3>
    </div>
</div>
