import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APP_CONFIG } from '../environment/Env';
import { SharedService } from '../service/shared-service';


@Component({
  selector: 'market-survey-popup',
  templateUrl: './market-survey.component.html',
  styleUrls: ['./market-survey.component.css']
})
export class MarketSurveyPopUpComponent {

  
  constructor(private _formBuilder: FormBuilder, private _http: HttpClient, private _sharedService: SharedService) {}

  contactForm: FormGroup = this._formBuilder.group({
    email: [,{validators: [Validators.required, Validators.email], updateOn: "change"}],
    name: [, { validators: [Validators.required], updateOn: "change" }],
    company: [, { validators: [Validators.required], updateOn: "change" }]
  });

  message: string;

  @Output() cancelEvent: EventEmitter<any> = new EventEmitter();
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  
  cancel() {
    this.cancelEvent.emit(true);
  }

  submit() {
    let data = {};
    data["name"] = this.contactForm.get("name").value;
    data["email"] = this.contactForm.get("email").value;
    data["company"] = this.contactForm.get("company").value;
    
    this._sharedService.openSpinner();
    this._http.post(APP_CONFIG.adminAPIPath+"/public/marketSurvey",data).subscribe(response=>{
        if(response["message"] == "SUCCESS") {
          this.successEvent.emit(data);
        }
        else {
          this.message = response["message"];
        }
        this._sharedService.closeSpinner();
    },
    error=>{
        this.message = "Failed submitting your Query";
        this._sharedService.closeSpinner();
    });
  }

}