import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APP_CONFIG } from '../environment/Env';
import { SharedService } from '../service/shared-service';
import { Router } from '@angular/router';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css'],
  providers: [DecimalPipe]
})
export class SubscriptionComponent {

  showPriceCalculationPanel: boolean = false;
  isPriceCalculated: boolean = false;
  standardPrice: number;
  rateChart: any;
  countries: any[] = [];
  countrycode: string = "";
  presentCountry: string = "";
  SubscriptionPanelContents: any[] = [];

  constructor(private _formBuilder: FormBuilder, private _http: HttpClient, private _sharedService: SharedService,private router: Router) {
    this._http.get(APP_CONFIG.adminAPIPath+"/public/basePrice").subscribe(data=>{
      this.standardPrice = data["standardPrice"];   
    });

    this._http.get(APP_CONFIG.adminAPIPath+"/public/discountRates").subscribe(data=>{
      this.rateChart = data;
    });

    this._http.get("assets/meta-data/country-list.json").subscribe((data:any[])=>{
      this.countries = data;
    });

    this._http.get(APP_CONFIG.adminAPIPath+"/public/getsubscription").subscribe((data:any[])=>{
      console.log(data);
      this.SubscriptionPanelContents = data;
    });
  }
  selectSubscription(details: any): void {
    this._sharedService.subscriptionDetails = details; // Store selected subscription
    this.router.navigate(['/signup']);
}
calculateEnterprisePrice(details: any) {
  const basePrice = details.price;
  const userCount = details.no_of_user || 1;
  const yearCount = details.years || 1;
  const discountRate=details.discount;

  // Calculate price with discount
  const totalPrice = basePrice * userCount * yearCount;
  //details.discountedPrice = totalPrice - totalPrice * (discountRate/100);
  details.discountedPrice =totalPrice * (discountRate/100)
}

  contactForm: FormGroup = this._formBuilder.group({
    email: [,{validators: [Validators.required, Validators.email], updateOn: "change"}],
    name: [, { validators: [Validators.required], updateOn: "change" }],
    text: [, { validators: [Validators.required], updateOn: "change" }],
    country: [, null],
    contact: [, null],
    frndemail: [,null],
    frndname: [, null],
  });

  calculationForm: FormGroup = this._formBuilder.group({
    users: [,{validators: [Validators.required,Validators.min(1)], updateOn: "change",}],
    years: [, { validators: [Validators.required,Validators.min(1)], updateOn: "change" }]
  });

  action() {
    this.showPriceCalculationPanel = true;
  }

  totalPrice: number;
  discount: number;
  priceAfterDiscount: number;
  pricePerYearPerUser: number;

  calculate() {
    
    let noOfUsers: number = this.calculationForm.get("users").value;
    let noOfYears: number = this.calculationForm.get("years").value;

    this.totalPrice = this.standardPrice * noOfUsers * noOfYears;

    let rateByUsersChart:[] = this.rateChart["perUserDiscount"];
    let rateByYearsChart:[] = this.rateChart["perYearDiscount"];

    let maxNoOfUsers: number = 0;
    let rateMaxUsers: number = 0;
    let maxNoOfYears: number = 0;
    let rateMaxYears: number = 0;

    rateByUsersChart.forEach(discountData=>{
      if(discountData["noOfUsers"] > maxNoOfUsers) {
        maxNoOfUsers = discountData["noOfUsers"];
        rateMaxUsers = discountData["rate"];
      }

      if(noOfUsers == discountData["noOfUsers"]) {
        this.priceAfterDiscount = this.totalPrice * ( 1 - discountData["rate"]/100 );
      }
    });

    if(noOfUsers > maxNoOfUsers) {
      this.priceAfterDiscount = this.totalPrice * ( 1 - rateMaxUsers/100 );
    }

    rateByYearsChart.forEach(discountData=>{
      if(discountData["noOfYears"] > maxNoOfYears) {
        maxNoOfYears = discountData["noOfYears"];
        rateMaxYears = discountData["rate"];
      }

      if(noOfYears == discountData["noOfYears"]) {
        this.priceAfterDiscount = Math.round(this.priceAfterDiscount * ( 1 - discountData["rate"]/100 ));
      }
    });

    if(noOfYears > maxNoOfYears) {
      this.priceAfterDiscount = Math.round(this.priceAfterDiscount * ( 1 - rateMaxYears/100 ));
    }

    this.discount = Math.round(100 * (this.totalPrice - this.priceAfterDiscount)/this.totalPrice);
    
    this.pricePerYearPerUser = Math.round(this.priceAfterDiscount / noOfYears / noOfUsers);

    this.isPriceCalculated = true;
  }

  showPopUp: boolean = false;
  showQuoteRequestPanel: boolean = false;
  quoteAction() {
    this.showQuoteRequestPanel = true;
    this.showForwardRequestPanel = false;
    this.showCallbackPanel = false;
    this.showPopUp = true;
    this.flag = "QT";
  }

  showCallbackPanel: boolean = false;
  callbackAction() {
    this.showCallbackPanel = true;
    this.showQuoteRequestPanel = false;
    this.showForwardRequestPanel = false;
    this.showPopUp = true;
    this.flag = "CB";
  }

  showForwardRequestPanel:boolean = false;
  forwardAction() {
    this.showForwardRequestPanel = true;
    this.showQuoteRequestPanel = false;
    this.showCallbackPanel = false;
    this.showPopUp = true;
    this.flag = "FF";
  }

  message: string;
  flag: string;
  submit() {
    let data = {};
    data["flag"]=this.flag;
    data["name"] = this.contactForm.get("name").value;
    data["email"] = this.contactForm.get("email").value;
    data["query"] = this.contactForm.get("text").value;
    data["contact"] = this.contactForm.get("country").value+""+this.contactForm.get("contact").value;
    this.countries.forEach(element => {
      if(element["code"] == this.countrycode) {
        data["country"] = element["name"];
      }
    });
    this._sharedService.openSpinner();
    this._http.post(APP_CONFIG.adminAPIPath+"/public/customerQueries",data).subscribe(data=>{
        if(data["message"] == "SUCCESS") {
          this.message = "Thank you for writing to us. We will get back to you."
        }
        else {
          this.message = "Failed submitting your Query";
        }
        this._sharedService.closeSpinner();
    },
    error=>{
        this.message = "Failed submitting your Query";
        this._sharedService.closeSpinner();
    });
  }

}