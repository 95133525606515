<div (window:resize)="setColumnLayout()">
    <div class="slideshow-container">

        <div class="mySlides fade">
        <img src="assets/images/floater/home/image1.jpg" style="width:100%; height:400px;">
        <div class="text" style="color: #1b1942; top: 10px; width: 60%; right: 10px; text-align: right;">
            <h1>Global Pharmaceutical Intelligence Data and Analytics</h1>
            <h2><i>One-stop Solution for Drug Product Intelligence</i></h2>
        </div>        
        </div>
        
        <div class="mySlides fade">
        <img src="assets/images/floater/home/image2.jpg" style="width:100%; height:400px;">
        <div class="text" style="color:#1b1942;width: 100% !important;text-align: center;">
            <table width="100%" style="text-align: center;height: 400px;">
                <tr>
                    <td style="vertical-align: bottom;"><h1>Product<br/>Intelligence</h1></td>
                    <td style="vertical-align: bottom;"><h1>Key Patent<br/>Intelligence</h1></td>
                    <td style="vertical-align: bottom;"><h1>Market<br/>Intelligence</h1></td>
                </tr>
                <tr>
                    <td colspan=3>
                        <p align="center"  style="font-size: 3em; font-weight: bold;">What we Offer</p>
                    </td>
                </tr>
                <tr valign="top">
                    <td><h1>Regulatory<br/>Intelligence</h1></td>
                    <td><h1>Competitive<br/>Intelligence</h1></td>
                    <td><h1>Litigation<br/>Intelligence</h1></td>
                </tr>                
            </table>
        </div>
        </div>
        
        <div class="mySlides fade">        
        <img src="assets/images/floater/home/image3.jpg" style="width:100%; height:400px;">
        <div class="text" style="top:50px;color:#FFFFFF;width: 100% !important;">
            <table width="100%" style="color:#FFF;text-align: center; margin: 0 auto;" id="floater3">
				<tr>
					<td>
						<h1>1M+</h1>
                        <h3>Global Key Patents</h3>
					</td>
					<td>
						<h1>50k+</h1>
                        <h3>Small Molecules & Biologics</h3>
					</td>
					<td>
						<h1>10k+</h1>
                        <h3>Quantitative Formulary</h3>
					</td>
				</tr>
                <tr>
					<td>
						<h1>40K+</h1>
                        <h3>PTE & SPE / 40 Countries</h3>
					</td>
					<td>
						<h1>70k+</h1>
                        <h3>DMFs / 10 Countries</h3>
					</td>
					<td>
						<h1>40 Countries</h1>
                        <h3>Data Exclusivity</h3>
					</td>
				</tr>
			</table>
            <p align="center" style="font-size: 3em; font-weight: bold;">Global Coverage</p>
        </div>
        </div>
        
        <a class="prev" (click)="plusSlides(-1)">&#10094;</a>
        <a class="next" (click)="plusSlides(1)">&#10095;</a>
        
    </div>
    <br>
        
    <div style="text-align:center">
        <span class="dot" (click)="slideShow(0)" [ngClass]="{'active' : slideNumber==0}"></span> 
        <span class="dot" (click)="slideShow(1)" [ngClass]="{'active' : slideNumber==1}"></span> 
        <span class="dot" (click)="slideShow(2)" [ngClass]="{'active' : slideNumber==2}"></span> 
    </div>
</div>
<br/>
<div class="content-box">
    <img src="assets/images/panel/first.jpg" height="220px" width="280px" style="float:left; margin: 0 15px 0 0 ">
    <div style="text-align: justify;">
        Drug Quester is a pharmaceutical intelligence database covering both small molecules and biological 
        drug products provides information and analytics. It is a single point source for your entire drug product 
        intelligence requirement. 
    </div>
    <br/>
    <div style="text-align: justify;">
        It covers both small molecule and biological drug products, their approvals, regulatory status, 
        marketing status, market intelligence, API sources, formulations, patents, scientific information, 
        regulatory requirement, manufactures, suppliers, US patent litigation, pipeline products and many more.
    </div>
    <br/>
    <div style="text-align: justify;">
        Freedom to search and refine data by more than 500 parameters for deep digging into the data to get required output. 
        It allows users to navigate effortlessly and focus only on what is essential. You can search, refine, analyzing and 
        then exporting the data in the form of intelligence. 
    </div>
    <br/>
    <div style="text-align: justify;">
        Our proprietary data and analytics are valuable for strategic decision making in a wide array of use cases. 
        Various stakeholders across different functions in the pharmaceutical industry like portfolio, business development, 
        sourcing, patent, regulatory, strategy, scientific, pharmacy, distribution, investment planning are using drug quester 
        for efficient decision making. 
    </div>
    <br/>
</div>
<br/><br/>
<div class="content-box">        
    <h3>Key Features</h3>
    <div style="display:flex;justify-content: center; height: 400px" *ngIf="!keyFeatureMobileVersion">
    <div>
        <div class="hex-row">
            <div class="hex">
                <div class="top"></div>
                <div class="middle">Market Intelligence</div>
                <div class="bottom"></div>
            </div>
            <div class="hex">
                <div class="top"></div>
                <div class="middle">Global Key Patent Information</div>
                <div class="bottom"></div>
            </div>
            <div class="hex">
                <div class="top"></div>
                <div class="middle">Regulatory Exclusivities</div>
                <div class="bottom"></div>
            </div>
            <div class="hex">
                <div class="top"></div>
                <div class="middle">Formulation & Package Details</div>
                <div class="bottom"></div>
            </div>
        </div>
        <div class="hex-row-even">
            <div class="hex">
                <div class="top"></div>
                <div class="middle">Drug Class & Indication</div>
                <div class="bottom"></div>
            </div>
            <div class="hex">
                <div class="top"></div>
                <div class="middle">Small Molecules & Biologics</div>
                <div class="bottom"></div>
            </div>
            <div class="hex">
                <div class="top"></div>
                <div class="middle">Suppliers and Manufacturers</div>
                <div class="bottom"></div>
            </div>
        </div>
        <div class="hex-row">
            <div class="hex">
                <div class="top"></div>
                <div class="middle">Clinical Trials</div>
                <div class="bottom"></div>
            </div>
            <div class="hex">
                <div class="top"></div>
                <div class="middle">Drug Master Files (10+ countries)</div>
                <div class="bottom"></div>
            </div>
            <div class="hex">
                <div class="top"></div>
                <div class="middle">US Patent Litigation</div>
                <div class="bottom"></div>
            </div>
            <div class="hex">
                <div class="top"></div>
                <div class="middle">Product Pipeline</div>
                <div class="bottom"></div>
            </div>
        </div>
    </div>    
    </div>
    <table align="center" width="100%" *ngIf="keyFeatureMobileVersion"><tr><td>
        <div class="subscribers-parent">
            <div class="subscriber-box">
                Market Intelligence
            </div>
            <div class="subscriber-box">
                Global Key Patent Information
            </div>
            <div class="subscriber-box">
                Regulatory Exclusivities
            </div>
        </div>
        <div class="subscribers-parent">
            <div class="subscriber-box">
                Formulation & Package Details
            </div>
            <div class="subscriber-box">
                Drug Class & Indication Analytics
            </div>
            <div class="subscriber-box">
                Approved Products for Small Molecules
            </div>
        </div>
        <div class="subscribers-parent">
            <div class="subscriber-box">
                Suppliers and Manufacturers
            </div>
            <div class="subscriber-box">
                Clinical Trials
            </div>
            <div class="subscriber-box">
                Drug Master Files (10+ countries)
            </div>
        </div>
        <div class="subscribers-parent">
            <div class="subscriber-box">
                US Patent Litigation
            </div>
            <div class="subscriber-box">
                Pipeline Products
            </div>            
        </div>
    </td></tr></table>
</div>
<br/><br/>
<div class="content-box">
    <img src="assets/images/panel/globe.png" height="220px" width="260px" style="float:left; margin: 0 15px 0 0">
    <h3>Countries Covered</h3>
    <h5>North America</h5>
    <div id="naCntryDiv" style="text-align: left;">
        <ul style="list-style-type: none; margin: 0">
            <li *ngFor="let country of countries['north-america']">
                {{country}}            
            </li>
        </ul>
    </div>    
    <h5>CIS Countries</h5>
    <div id="cisCntryDiv" style="text-align: left;">
        <ul style="list-style-type: none; margin: 0">
            <li *ngFor="let country of countries['cis']">
                {{country}}            
            </li>
        </ul>
    </div>
    <h5>Asia & Pacific</h5>
    <div id="asiaCntryDiv" style="text-align: left; width: 100%;">
        <ul style="list-style-type: none; margin: 0">
            <li *ngFor="let country of countries['asia']">
                {{country}}            
            </li>
        </ul>
    </div>
    <h5>Europe</h5>
    <div id="euCntryDiv" style="text-align: left;">
        <ul style="list-style-type: none; margin: 0">
            <li *ngFor="let country of countries['europe']">
                {{country}}            
            </li>
        </ul>
    </div>
    <h5>Latin America</h5>
    <div id="latinCntryDiv" style="text-align: left; width: 100%;">
        <ul style="list-style-type: none; margin: 0">
            <li *ngFor="let country of countries['latin-america']">
                {{country}}            
            </li>
        </ul>        
    </div>
    <h5>Middle East and North Africa (MENA)</h5>
    <div id="menaCntryDiv" style="text-align: left; width: 100%;">
        <ul style="list-style-type: none; margin: 0">
            <li *ngFor="let country of countries['mena']">
                {{country}}            
            </li>
        </ul>
    </div>
</div>