<div id="signin" style="min-height: calc(100vh - 175px);" class="content-background">
    <br/><br/>
    <div>        
        <h3 style="text-align: center; color: #FFF;">Welcome back to Drug Quester. Please sign in to continue.</h3>
        <h2 style="text-align: center; color: #FFF;" align="center" *ngIf="message != undefined">{{message}}</h2>
        <div class="login-panel">
            <form class="example-form" [formGroup]="loginForm">
                <mat-form-field class="form-field-full-width">
                    <mat-label>Email</mat-label>
                    <input type="email" required matInput formControlName="email" placeholder="Ex. pat@example.com">
                    <mat-error *ngIf="loginForm.get('email').hasError('email') && !loginForm.get('email').hasError('required')">
                        Please enter a valid email address
                    </mat-error>
                    <mat-error *ngIf="loginForm.get('email').hasError('required')">
                        Email is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <br/>
                <mat-form-field class="form-field-full-width">
                    <mat-label>Password</mat-label>
                    <input matInput required #password formControlName="password" type="password">
                    <mat-error *ngIf="loginForm.get('password').hasError('required')">
                        Password is required!
                    </mat-error>        
                </mat-form-field>

                <br/>
                <mat-checkbox formControlName="flag" class="form-field-full-width">Keep me signed in.</mat-checkbox>
                <br/>

                <button mat-raised-button class="form-field-full-width" color="primary" [disabled]="loginForm.invalid" (click)="login()">Login</button>
                <div style="text-align: right;">
                    <a routerLink="/recovery" routerLinkActive="active">Forgot Password ?</a>    
                </div>
            </form>
        </div>
        <h3 style="color:#FFF;text-align: center;">Don’t have an account! 
            <a routerLink="/signup" routerLinkActive="active" style="color:#FFF">Register now!</a>
        </h3>
    </div>
</div>