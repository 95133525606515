import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component'; 
import { AppRoutingModule } from './app-routing.module';

import { NgxCaptchaModule } from 'ngx-captcha';

import { CookieService } from 'ngx-cookie-service';

import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { AboutUsComponent } from './aboutus/aboutus.component';
import { ContactComponent } from './contact/contatc.component';
import { SubscribersComponent } from './subscribers/subscribers.component';
import { InsightComponent } from './insight/insight.component';
import { NewsRoomComponent } from './newsroom/newsroom.component';
import { CareerComponent } from './career/career.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TncComponent } from './tnc/tnc.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { DemoComponent } from './demo/demo.component';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { BlogComponent } from './blog/blog.component';
import { UserGuideComponent } from './userguide/userguide.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { TncPopupComponent } from './tnc/tnc-popup.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { Insight1Component } from './insight/insight.component1';
import { PrivacyPopupComponent } from './privacy/privacy-popup.component';
import { HTTPService } from './service/http-service';
import { SharedService } from './service/shared-service';
import { WindowRef } from './service/windowref';
import { SubscriptionPopUpComponent } from './subscription/subscription-popup.component';
import { PassrordComponent } from './recovery/password.component';
import { MarketSurveyPopUpComponent } from './insight/market-survey.component';
import { JobApplicationComponent } from './career/job-application.component';
import { MaterialModule } from './material.module';

@NgModule({
  declarations: [
    AppComponent, 
    HeaderComponent, HomeComponent, FooterComponent, AboutUsComponent, 
    ContactComponent, SubscribersComponent, InsightComponent, NewsRoomComponent, 
    CareerComponent, TncComponent, PrivacyComponent, DemoComponent, 
    SigninComponent, SignupComponent, CopyrightComponent, BlogComponent,
    UserGuideComponent, SubscriptionComponent, TncPopupComponent,
    RecoveryComponent, PassrordComponent, Insight1Component, MarketSurveyPopUpComponent, 
    PrivacyPopupComponent, SubscriptionPopUpComponent, JobApplicationComponent
  ],
  imports: [
    MaterialModule, BrowserModule,AppRoutingModule,FormsModule, NgxCaptchaModule, 
    ReactiveFormsModule, BrowserAnimationsModule, HttpClientModule
  ],
  providers: [ CookieService, HTTPService, SharedService, WindowRef ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
