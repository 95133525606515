import { Component } from '@angular/core';


@Component({
  selector: 'about-us',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})
export class AboutUsComponent {

  slideNumber: number;
  timer: any;

  ngOnInit() {
    setTimeout(()=>{this.slideShow(0)},1000);

    this.timer = setInterval(()=>{
      this.slideShow(++this.slideNumber);
    }, 10000);
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }
  
  plusSlides(offset: number) {
    this.slideShow(this.slideNumber+offset);
  }

  slideShow(slideNo: number) {
    this.slideNumber = slideNo;

    let elements = document.getElementsByClassName("mySlides");
    for(let indx=0;indx<elements.length;indx++) {
      let slide: Element = elements[indx];
      (slide as HTMLElement).style.display="none";
    }
    if(this.slideNumber >= elements.length) {
      this.slideNumber = 0;
    }
    if(this.slideNumber < 0) {
      this.slideNumber = elements.length - 1;
    }
    let slide: Element = elements[this.slideNumber];
    (slide as HTMLElement).style.display="block";    

  }

}
