import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { APP_CONFIG } from '../environment/Env';
import { HTTPService } from '../service/http-service';
import { SharedService } from '../service/shared-service';


@Component({
  selector: 'contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {
  countries: any;
  countrycode: string = "";
  presentCountry: string = "";

  @ViewChild('formDirective') private formDirective: NgForm;
  
  constructor(private _formBuilder: FormBuilder, private _httpService: HTTPService, private _sharedService:SharedService) {
    this._httpService.get("assets/meta-data/country-list.json").subscribe(data=>{
      this.countries = data;
    });
  }

  contactForm: FormGroup = this._formBuilder.group({
    email: [,{validators: [Validators.required, Validators.email], updateOn: "change"}],
    name: [, { validators: [Validators.required], updateOn: "change" }],
    text: [, { validators: [Validators.required], updateOn: "change" }],
    country: [, { validators: [Validators.required], updateOn: "change" }],
    contact: [, { validators: [Validators.required], updateOn: "change" }],
    company: [, { validators: [Validators.required], updateOn: "change" }],
  });

  message: string;
  submit() {
    let data = {};
    data["name"] = this.contactForm.get("name").value;
    data["email"] = this.contactForm.get("email").value;
    data["query"] = this.contactForm.get("text").value;
    data["contact"] = this.contactForm.get("country").value+""+this.contactForm.get("contact").value;
    this.countries.forEach(element => {
      if(element["code"] == this.countrycode) {
        data["country"] = element["name"];
      }
    });
    data["company"] = this.contactForm.get("company").value;
    this._sharedService.openSpinner();
    this._httpService.post(APP_CONFIG.adminAPIPath+"/public/customerQueries",data).subscribe(data=>{
        if(data["message"] == "SUCCESS") {
          this.message = "Thank you for writing to us. We will get back to you soon.";
          this.formDirective.resetForm();
        }
        else {
          this.message = "Failed submitting your Query";
        }
        this._sharedService.closeSpinner();
    },
    error=>{
        this.message = "Failed submitting your Query";
        this._sharedService.closeSpinner();
    });
  }

}
