import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { APP_CONFIG } from '../environment/Env';
import { HTTPService } from '../service/http-service';
import { SharedService } from '../service/shared-service';
import { Router } from '@angular/router';


@Component({
  selector: 'demo',
  templateUrl: './demo.component.html'
})
export class DemoComponent {

  countries: any[] = [];
  countrycode: string = "";

  @ViewChild('formDirective') private formDirective: NgForm;

  constructor(private _formBuilder: FormBuilder,private router: Router ,private _httpService: HTTPService, private _sharedService:SharedService) {
    this._httpService.get("assets/meta-data/country-list.json").subscribe((data:any[])=>{
      this.countries = data;
    });
  }

  contactForm: FormGroup = this._formBuilder.group({
    email: [,{validators: [Validators.required, Validators.email], updateOn: "change"}],
    name: [, { validators: [Validators.required], updateOn: "change" }],
    text: [, { validators: [Validators.required], updateOn: "change" }],
    position:[, { updateOn: "change" }],
    department:[, { updateOn: "change" }],
    country: [, { validators: [Validators.required], updateOn: "change" }],
    company: [, { validators: [Validators.required], updateOn: "change" }],
    contactNo: [, { validators: [Validators.required], updateOn: "change" }]
  });

  message: string;
  submit() {
    let data = {};
    data["name"] = this.contactForm.get("name").value;
    data["email"] = this.contactForm.get("email").value;
    data["position"] = this.contactForm.get("position").value;
    data["department"] = this.contactForm.get("department").value;
    data["company"] = this.contactForm.get("company").value;
    data["contactNo"] = this.countrycode+this.contactForm.get("contactNo").value;
    data["message"] = this.contactForm.get("text").value;
    this.countries.forEach(element => {
      if(element["code"] == this.countrycode) {
        data["country"] = element["name"];
      }
    });

    this._sharedService.openSpinner();
    this._httpService.post(APP_CONFIG.adminAPIPath+"/public/demoRequest",data).subscribe(data=>{
        if(data["message"] == "SUCCESS") {
          this.message = "Thank you for writing to us. We will get back to you soon.";
          this.formDirective.resetForm();
        }
        else {
          this.message = "Failed submitting your Query";
        }
        this._sharedService.closeSpinner();
    },
    error=>{
        this.message = "Failed submitting your Query";
        this._sharedService.closeSpinner();
    });
  }
  goHome() {
    this.router.navigate(['']); // Replace '/home' with your actual home route
  }
}