import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators ,AbstractControl} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { APP_CONFIG } from '../environment/Env';
import { SharedService } from '../service/shared-service';
import { TncPopupComponent } from '../tnc/tnc-popup.component';
import { Router } from '@angular/router';

function passwordMatchValidator(control: AbstractControl) {
  const password = control.get('password');
  const confirmPassword = control.get('confirmPassword');
  return password && confirmPassword && password.value === confirmPassword.value ? null : { mismatch: true };
}
@Component({
  selector: 'signup',
  templateUrl: './signup.component.html',
  styleUrls: [ './signup.component.css' ]
})

export class SignupComponent {
  
  /*step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }*/

  standardPrice: number;
  rateChart: any;
  countries: any;
  countrycode: string = "";
  presentCountry: string = ""
  fxRate: number = 1;
  selectedSubscription: any;
  
  //message: string = "";
  registerForm: FormGroup;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder, 
    public _dialog: MatDialog, 
    private _http: HttpClient, 
    private _sharedService: SharedService,
    private _route: ActivatedRoute,
    private router: Router
  ) {
    
    this._http.get("assets/meta-data/standard-price.json").subscribe(data=>{
      this.standardPrice = data["standardPrice"];   
    });

    this._http.get("assets/meta-data/discount-rates.json").subscribe(data=>{
      this.rateChart = data;
    });

    this._http.get("assets/meta-data/country-list.json").subscribe(data=>{
      this.countries = data;
    });
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      country:['Select Country',Validators.required],
      mobile: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required],
      company: ['', Validators.required],
      position: ['', Validators.required],
      department: ['']
  }, { validators: passwordMatchValidator });

  }
  ngOnInit(): void {
    // Retrieve the subscription details passed from SubscriptionComponent
    this.selectedSubscription = this._sharedService.subscriptionDetails;

    // Check if data is available
    if (this.selectedSubscription) {
        console.log('Selected Subscription:', this.selectedSubscription);
    } else {
        console.log('No subscription data found');
    }
}


  referenceId: string;
  subscriptionType: string = "";

  

  message: string = "";
  emailId: string;

 /* signup() {
    this.submitted = true;
    this.emailId = this.registerForm.get("email").value;
    this._sharedService.openSpinner();
    let userData = {};
    userData["firstName"] = this.registerForm.get("firstName").value;
    userData["lastName"] = this.registerForm.get("lastName").value;
    userData["email"] = this.registerForm.get("email").value;
    userData["password"] = this.registerForm.get("password").value;
    userData["company"] = this.registerForm.get("company").value;
    userData["position"] = this.registerForm.get("position").value;
    userData["department"] = this.registerForm.get("department").value;
    userData["city"] = this.registerForm.get("city").value;
    userData["contact"] = this.countrycode + this.registerForm.get("contact").value;
    userData["subscriptionType"] = "S"; //this.subscriptionType;
    userData["currency"] = this.currency;
    userData["countryCode"] = this.countrycode;
    userData["trial"] = "N"
    // if(this.registerForm.get("trial").value) {
    //   userData["trial"] = "Y";
    // }
    // else {
    //   userData["trial"] = "N";
    // }
    if(this.subscriptionType == "M") {
      userData["users"] = this.registerForm.get("users").value;
      userData["years"] = this.registerForm.get("years").value;
      userData["amount"] = this.priceAfterDiscount;
    }
    else {
      userData["amount"] = this.totalPrice;
    }
    this.countries.forEach(element => {
      if(element["code"] == this.countrycode) {
        userData["country"] = element["name"];
      }
    });
    //console.log(userData);
    
    
    
    this._http.post(APP_CONFIG.adminAPIPath+"/public/signup",userData).subscribe(data=>{
      this.message = data["message"];
      this._sharedService.closeSpinner();
    },
    error=>{
      this.message = "Account activation failed";
      this._sharedService.closeSpinner();
    });
  }*/
  
signup() {
  this.submitted = true;
  
  // Trigger validation messages if form is invalid
  if (this.registerForm.invalid) {
      return;
  }
  
  // Collect user data from individual form controls as per your custom requirements
  const userData: any = {
      firstName: this.registerForm.get("firstName")?.value,
      lastName: this.registerForm.get("lastName")?.value,
      email: this.registerForm.get("email")?.value,
      password: this.registerForm.get("password")?.value,
      company: this.registerForm.get("company")?.value,
      position: this.registerForm.get("position")?.value,
      department: this.registerForm.get("department")?.value,
     // city: this.registerForm.get("city")?.value,
     contact: this.countrycode + this.registerForm.get('mobile')?.value,
     country: this.countries.find(c => c.code === this.countrycode)?.name,
     // subscriptionType: "S",this.selectedSubscription.
     //subscriptionType: this.selectedSubscription.subscription_name,
     subscriptionType: "S",

     currency: this.selectedSubscription ?.currency,
     
      //countryCode: this.countrycode,
  };
  userData["trial"] = "N";
  if(this.selectedSubscription?.subscription_name==="Eneterprise")
  {
    userData["users"] = this.selectedSubscription.no_of_user;
      userData["years"] = this.selectedSubscription.years;
      userData["amount"] = this.selectedSubscription.discountedPrice;
      userData["subscriptionType"] = "M";

  }
  else{
    userData["amount"] = this.selectedSubscription?.price;
    userData["subscriptionType"] = "S"
  }
  this.countries.forEach(element => {
    if(element["code"] == this.countrycode) {
      userData["country"] = element["name"];
    }
  });
  
  // Optional fields based on conditions
  /*if (this.subscriptionType === "M") {
      userData["users"] = this.registerForm.get("users")?.value;
      userData["years"] = this.registerForm.get("years")?.value;
      userData["amount"] = this.priceAfterDiscount;
  } else {
      userData["amount"] = this.totalPrice;
  }*/

  // Map country based on country code
  //const selectedCountry = this.countries.find(element => element["code"] === this.countrycode);
 /* if (selectedCountry) {
      userData["country"] = selectedCountry["name"];
  }*/

  // Open spinner, send data, and handle response
 
  this._sharedService.openSpinner();
  this._http.post(APP_CONFIG.adminAPIPath + "/public/signup", userData).subscribe(
      data => {
          console.log('Signup successful', data);
          this.message = data["message"];
          this._sharedService.closeSpinner();
      if (this.message === 'PAYMENT_PENDING' || this.message === 'success') {
        this.router.navigate(['/signin']);
      }
          
      },
      error => {
          console.log('Signup failed', error);
          this.message = "Account activation failed";
          this._sharedService.closeSpinner();
      }
  );
}



  openTnCDialog() {
    const dialogRef = this._dialog.open(TncPopupComponent);  
    dialogRef.afterClosed().subscribe(result => {});
      
  }

  totalPrice: number;
  discount: number;
  priceAfterDiscount: number;
  pricePerYearPerUser: number;
  currency: string = "USD";

  siteKey: string = "6LdJ3qgaAAAAAFHCNHgr9jttubbYSxVBnApQzNeN";

  handleSuccess(event) {

  }
  goSignup(){
    this.router.navigate(['/signin']);
  }
  
}

