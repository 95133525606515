import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './aboutus/aboutus.component';
import { BlogComponent } from './blog/blog.component';
import { CareerComponent } from './career/career.component';
import { JobApplicationComponent } from './career/job-application.component';
import { ContactComponent } from './contact/contatc.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { DemoComponent } from './demo/demo.component';
import { HomeComponent } from './home/home.component';
import { InsightComponent } from './insight/insight.component';
import { Insight1Component } from './insight/insight.component1';
import { NewsRoomComponent } from './newsroom/newsroom.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { PassrordComponent } from './recovery/password.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { SubscribersComponent } from './subscribers/subscribers.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { TncComponent } from './tnc/tnc.component';
import { UserGuideComponent } from './userguide/userguide.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'aboutDQ', component: AboutUsComponent },
  { path: 'subscribers', component: SubscribersComponent },
  { path: 'insight', component: InsightComponent },
  { path: 'insight-cgt', component: Insight1Component },
  { path: 'subscription', component: SubscriptionComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'newsRoom', component: NewsRoomComponent },
  { path: 'demoRequest', component: DemoComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'signin', component: SigninComponent },
  { path: 'career', component: CareerComponent },
  { path: 'tnc', component: TncComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'copyright', component: CopyrightComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'recovery', component: RecoveryComponent },
  { path: 'r', component: PassrordComponent },
  { path: 'userGuide', component: UserGuideComponent },
  { path: 'job-application', component: JobApplicationComponent },
  { path: '', redirectTo: '', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
