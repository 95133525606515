<div id="career" style="min-height: calc(100vh - 175px);">
    <br/><br/>
    <div class="content-box">
        <h3 align="center">{{message}}</h3>
        <mat-horizontal-stepper [linear]="isLinear" #stepper *ngIf="message == '' && jobPosition != undefined">
            <mat-step [stepControl]="personaDetailGroup">
                <form [formGroup]="personaDetailGroup">
                    <ng-template matStepLabel>Personal Details</ng-template>
                    <mat-form-field class="form-field-half-width">
                        <mat-label>First Name</mat-label>
                        <input matInput required formControlName="firstName">
                        <mat-error *ngIf="personaDetailGroup.get('firstName').hasError('required')">
                            First Name is required!
                        </mat-error>
                    </mat-form-field>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <mat-form-field class="form-field-half-width">
                        <mat-label>Last Name</mat-label>
                        <input matInput required formControlName="lastName">
                        <mat-error *ngIf="personaDetailGroup.get('lastName').hasError('required')">
                            Last Name is required!
                        </mat-error>
                    </mat-form-field>
                    <br/>
                    <mat-form-field class="form-field-full-width">
                        <mat-label>Email</mat-label>
                        <input type="email" required matInput formControlName="email" placeholder="Ex. pat@example.com">
                        <mat-error *ngIf="personaDetailGroup.get('email').hasError('email') && !personaDetailGroup.get('email').hasError('required')">
                            Please enter a valid email address
                        </mat-error>
                        <mat-error *ngIf="personaDetailGroup.get('email').hasError('required')">
                            Email is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                    <br/>
                    <mat-form-field class="form-field-full-width">
                        <mat-label>Contact</mat-label>
                        <span matPrefix>+91 </span>
                        <input matInput required formControlName="contact">
                        <mat-error *ngIf="personaDetailGroup.get('contact').hasError('required')">
                            Contact is required!
                        </mat-error>
                    </mat-form-field>
                    <br/>
                    <div style="float:right">
                        <button mat-button matStepperNext>Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="experienceGroup" label="Experience">
                <form [formGroup]="experienceGroup">
                    <mat-form-field class="form-field-full-width">
                        <mat-label>Experience (No of Years)</mat-label>
                        <input matInput formControlName="experience">
                        <mat-error *ngIf="experienceGroup.get('experience').hasError('required')">
                            Total experience is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                    <br/>
                    <mat-form-field class="form-field-full-width">
                        <mat-label>LinkedIn profile</mat-label>
                        <input matInput formControlName="website">
                    </mat-form-field>
                    <br/>
                    <mat-form-field>
                        <mat-label>Resume</mat-label>
                        <input matInput readonly formControlName="file">
                        <mat-error *ngIf="experienceGroup.get('file').hasError('required')">
                            Resume is required
                        </mat-error>
                    </mat-form-field>
                    &nbsp;&nbsp;
                    <button mat-button color="accent" (click)="onClick()">
                        <mat-icon>file_upload</mat-icon>
                        Upload
                    </button>
                    <input type="file" id="fileUpload" name="fileUpload" accept=".doc,.docx,.pdf" style="display:none;"/>
                    <h6>Only word/pdf documents are allowed.</h6>
                    <br/>
                    <div style="float:right">
                        <button mat-button matStepperPrevious>Back</button>
                        <button mat-button matStepperNext>Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="questionaireGroup" label="Questionary">
                <form [formGroup]="questionaireGroup">
                    <mat-form-field class="form-field-full-width">
                        <mat-label>Expected CTC</mat-label>
                        <input matInput required formControlName="ctc">
                        <mat-error *ngIf="questionaireGroup.get('ctc').hasError('required')">
                            Expected CTC is required!
                        </mat-error>
                    </mat-form-field>
                    <br/>
                    <mat-form-field class="form-field-full-width">
                        <mat-label>When can you start?</mat-label>
                        <input matInput formControlName="startDate" [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <mat-error *ngIf="questionaireGroup.get('ctc').hasError('required')">
                            Proposed start date is madatory
                        </mat-error>
                    </mat-form-field>
                    <br/>
                    Are you willing to relocate ? &nbsp;&nbsp;&nbsp;
                        <mat-radio-group aria-label="Are you willing to relocate ?" formControlName="relocation" required>
                            <mat-radio-button value="Y">Yes</mat-radio-button>
                            &nbsp;&nbsp;&nbsp;
                            <mat-radio-button value="N">No</mat-radio-button>
                        </mat-radio-group>
                        <mat-error *ngIf="questionaireGroup.get('relocation').hasError('required')">
                            <h5>Relocation preferance is mandatory</h5>
                        </mat-error>
                    <br/>
                    <mat-form-field class="form-field-full-width">
                        <mat-label>Last/Current Company</mat-label>
                        <input matInput required formControlName="company">
                        <mat-error *ngIf="questionaireGroup.get('company').hasError('required')">
                            Company name is required!
                        </mat-error>
                    </mat-form-field>
                    <br/>
                    References/Comments/Questions
                    <br/>
                    <mat-form-field class="form-field-full-width">
                        <textarea matInput formControlName="comment" rows="5" cols="50"></textarea>
                    </mat-form-field>
                    <br/>
                    <div style="float: right;">
                        <button mat-button matStepperPrevious>Back</button>
                        <button mat-button matStepperNext>Next</button>
                    </div>
                </form>
            </mat-step>
            
            <mat-step>
                <ng-template matStepLabel>Summary</ng-template>
                <table>
                    <tr><th>Job ID</th><td>{{jobId}}</td></tr>
                    <tr><th>Position</th><td>{{jobPosition}}</td></tr>
                    <tr><th>Department</th><td>{{jobDepartment}}</td></tr>
                    <tr><th>First Name</th><td>{{personaDetailGroup.get("firstName").value}}</td></tr>
                    <tr><th>Last Name</th><td>{{personaDetailGroup.get("lastName").value}}</td></tr>
                    <tr><th>e-mail</th><td>{{personaDetailGroup.get("email").value}}</td></tr>
                    <tr><th>Contact</th><td>+91{{personaDetailGroup.get("contact").value}}</td></tr>
                    <tr><th>Experience</th><td>{{experienceGroup.get("experience").value}}</td></tr>
                    <tr><th>Portfolio Website</th><td>{{experienceGroup.get("website").value}}</td></tr>
                    <tr><th>Resume</th><td>{{experienceGroup.get("file").value}}</td></tr>
                    <tr><th>Expected CTC</th><td>{{questionaireGroup.get("ctc").value}}</td></tr>
                    <tr><th>Start Date</th><td>{{questionaireGroup.get("startDate").value | date: 'dd/MM/YYYY'}}</td></tr>
                    <tr><th>Willing to relocate ?</th><td>{{questionaireGroup.get("relocation").value}}</td></tr>
                    <tr><th>Last/Current company</th><td>{{questionaireGroup.get("company").value}}</td></tr>
                    <tr><th>Comments/Reference/Questions</th><td>{{questionaireGroup.get("comment").value}}</td></tr>
                </table>
              <br/>
              <div style="float: right;">
                <button mat-button matStepperPrevious>Back</button>
                <button mat-button (click)="stepper.reset()">Reset</button>
                <button mat-button (click)="onSubmit()">Submit</button>
              </div>
            </mat-step>
        </mat-horizontal-stepper>
    </div>    
</div>