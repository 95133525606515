<div class="popup">
    <div class="content-box" style="background-color: #FFFFFF; margin-top: 10%;">
        <form class="example-form" [formGroup]="contactForm">
            <h2>Please fill the details to download.</h2>
            <table align="center" cellspacing="10px">
                <tr>
                    <td>
                        <mat-form-field class="form-field-full-width">
                            <mat-label>Name</mat-label>
                            <input required matInput formControlName="name" placeholder="">
                            <mat-error *ngIf="contactForm.get('name').hasError('required')">
                                Name is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="form-field-full-width">
                            <mat-label>Email</mat-label>
                            <input type="email" required matInput formControlName="email" placeholder="Ex. pat@example.com">
                            <mat-error *ngIf="contactForm.get('email').hasError('email') && !contactForm.get('email').hasError('required')">
                                Please enter a valid email address
                            </mat-error>
                            <mat-error *ngIf="contactForm.get('email').hasError('required')">
                                Email is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </td>
                    <td>
                        <mat-form-field class="form-field-full-width">
                            <mat-label>Company</mat-label>
                            <input required matInput formControlName="company" placeholder="">
                            <mat-error *ngIf="contactForm.get('company').hasError('required')">
                                Company Name is <strong>required</strong>
                            </mat-error>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td align="center" colspan="2">
                        <button 
                            mat-raised-button 
                            color="primary" 
                            [disabled]="contactForm.invalid"
                            (click)="submit()"
                        >
                            Submit
                        </button>&nbsp;&nbsp;      
                        <button mat-raised-button color="warn"  (click)="cancel()">Cancel</button>
                    </td>
                </tr>
            </table>
        </form>
        <h3 align="center" *ngIf="message != ''">{{message}}</h3>
    </div>
</div>
