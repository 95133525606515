<div id="insight" style="min-height: calc(100vh - 175px);">
    <br/><br/>
    <div class="content-box">        
        <h3 align="center">
            Competitive Generic Therapies (CGT): A Regulatory Pathway for Unattractive Drug Products to Make it Attractive
        </h3>
        <mat-accordion class="example-headers-align">
            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                <div style="text-align: justify; text-indent: 25px;">
                    In 2017, to bring more drug competition to the market and reduce cost of prescription, FDA announced the Drug Competition Action Plan (DCAP) to encourage robust and timely market competition for generic drugs and help bring greater efficiency and transparency to the generic drug review process, without sacrificing the scientific rigor underlying generic drug program. To remove barriers to generic drug development and market entry to spur competition so that consumers can get access to the medicines they need at affordable prices, FDA brings FDA Reauthorization Act of 2017 (FDARA). The FDA Reauthorization Act of 2017 (FDARA) provides a new pathway to for approval of generic products designated as “Competitive generic therapies” (CGT) for drugs for which there is “inadequate generic competition.” 
                </div>
                <br/>
                <div style="text-align: justify; text-indent: 25px;">
                    This is to expedite the development and review of certain drugs with a quicker and rewarding process to bring more generic drug products to market that lack competition. The term “inadequate generic competition” refers to a situation where there is not more than one approved drug included in the active section of the Orange Book, the commonly known name of the FDA’s publication, Approved Drug Products with Therapeutic Equivalence Evaluations, which identifies drugs approved by the FDA and the related patent and exclusivity information; there shall be no unexpired patents or exclusivities listed in the Orange Book. Applicants for drugs that receive a CGT designation may receive review enhancements and expedited review of their abbreviated new drug applications (ANDA). Applicants for drugs that receive a CGT designation are also eligible for a 180-day period of marketing exclusivity if they are the first-approved applicant for that CGT.
                </div>
                <mat-action-row>
                    <button mat-button color="primary" (click)="nextStep()">Next</button>
                </mat-action-row>          
            </mat-expansion-panel>
          
            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
                <div style="text-align: justify; text-indent: 25px;">
                    On March 13, 2020, FDA issued final guidance on Competitive Generic Therapies bringing more clarity to this new path. As per the guideline, an applicant can request to designate a drug as a CGT (Requests for Designation) prior to or concurrently with an original ANDA submission. FDA will not consider Requests for Designation as timely if they are submitted after the submission of the original ANDA, including as an amendment during filing review, except for an application for which a refuse-to-receive (RTR) determination has been made. The applicant requests for CGT Designation should include identification of the reference listed drug that is the basis of the submission and information supporting the applicant’s assertion that there is inadequate generic competition.  FDA intends to make a determination on a Request for Determination within 60 days of the request. Once a product in an applicant’s ANDA gets CGT designation, that designation remains even if there is no longer inadequate generic competition prior to approval of that application. 
                </div>
                <br/>
                <div style="text-align: justify; text-indent: 25px;">
                    A CGT exclusivity of 180-day marketing exclusivity period is granted for ANDA applicants of certain drugs that FDA has designated as CGTs; and the CGT exclusivity only granted only if the applicant is a “first approved applicant” as defined in section 505(j)(5)(B)(v) of the FD&C Act.  There is only one exclusivity period available for each CGT; and receiving a CGT designation does not ensure that a drug product will be eligible for CGT exclusivity like there will be no CGT exclusivity if there were unexpired patents or exclusivities listed in the Orange Book at the time of the original submission of the ANDA for the CGT-designated drug product. Further, the CGT exclusivity can be forfeited the eligibility if the product is not commercially marketed within 75 days after the date of approval of the ANDA. The 180-day CGT exclusivity period is triggered by the first commercial marketing of the competitive generic therapy (including the commercial marketing of the listed drug) by any first approved applicant.  The 180-day CGT exclusivity period, once triggered, blocks the approval of an ANDA for a drug that is the same as the CGT.
                </div>
                <mat-action-row>
                    <button mat-button color="warn" (click)="prevStep()">Previous</button>
                    <button mat-button color="primary" (click)="nextStep()">Next</button>
                </mat-action-row>
            </mat-expansion-panel>
          
            <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
                <div style="text-align: justify; text-indent: 25px;">
                    Potassium chloride oral solution having strengths 20MEQ/15ML, 40MEQ/15ML was the first drug got approved under the Competitive Generic Therapy (CGT) designation to Canada-based Apotex. Thereafter, 80 products have been approved filed under CGT designation by various companies till Mar 31, 2021. Out of 80 approved products, 50 products are for oral administration, followed 14 injectable products (combining with infusion, IV, IM etc.) and 8 topical products. Likewise, there are highest no. of solid dosage forms tablet, capsule, followed by injection and solutions.
                </div>
                <br/>
                <div style="width:100%">
                    <img src="assets/images/insights/insight1.JPG" class="image-center"/>
                </div>
                <br/>
                <div style="width:100%">
                    <img src="assets/images/insights/insight2.JPG" class="image-center"/>
                </div>
                <mat-action-row>
                    <button mat-button color="warn" (click)="prevStep()">Previous</button>
                    <button mat-button color="primary" (click)="nextStep()">Next</button>
                </mat-action-row>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step === 3" (opened)="setStep(3)" hideToggle>
                <div style="text-align: justify; text-indent: 25px;">
                    CGT applications are for those drugs, where there is inadequate generic competition for that drug. The term inadequate generic competition is defined to mean, with respect to a drug, that there is not more than one approved drug included in the active section of the Orange Book. This may either be the reference listed drug (RLD) or a drug approved in an ANDA referencing the same RLD as the drug for which designation as a CGT is sought. If there is not more than one such drug in the active section of the Orange Book, including if there are no drugs in the active section of the Orange Book because any approved drugs have been discontinued from marketing and are reflected as such in the Orange Book, a product is eligible for designation as a CGT. So far out of approved 80 ANDAs, 70% products are filed against active prescription reference products, whereas 27% products are filed against discontinued products and 3% products are filed against active OTC products. The reference products are approved as old as 1946 till as new as approved in 2019.
                </div>
                <br/>
                <div style="width:100%">
                    <img src="assets/images/insights/insight3.JPG" class="image-center"/>
                </div>
                <br/>
                <div style="width:100%">
                    <img src="assets/images/insights/insight4.JPG" class="image-center"/>
                </div>
                <mat-action-row>
                    <button mat-button color="warn" (click)="prevStep()">Previous</button>
                    <button mat-button color="primary" (click)="nextStep()">Next</button>
                </mat-action-row>
            </mat-expansion-panel>
          
            <mat-expansion-panel [expanded]="step === 4" (opened)="setStep(4)" hideToggle>
                <div style="text-align: justify; text-indent: 25px;">
                    CGT applications are for those drugs, where there is inadequate generic competition for that drug. The term inadequate generic competition is defined to mean, with respect to a drug, that there is not more than one approved drug included in the active section of the Orange Book. This may either be the reference listed drug (RLD) or a drug approved in an ANDA referencing the same RLD as the drug for which designation as a CGT is sought. If there is not more than one such drug in the active section of the Orange Book, including if there are no drugs in the active section of the Orange Book because any approved drugs have been discontinued from marketing and are reflected as such in the Orange Book, a product is eligible for designation as a CGT. So far out of approved 80 ANDAs, 70% products are filed against active prescription reference products, whereas 27% products are filed against discontinued products and 3% products are filed against active OTC products. The reference products are approved as old as 1946 till as new as approved in 2019.
                </div>
                <br/>
                <div style="text-align: justify; text-indent: 25px;">
                    CGT applications are for those drugs, where there is inadequate generic competition for that drug. The term inadequate generic competition is defined to mean, with respect to a drug, that there is not more than one approved drug included in the active section of the Orange Book. This may either be the reference listed drug (RLD) or a drug approved in an ANDA referencing the same RLD as the drug for which designation as a CGT is sought. If there is not more than one such drug in the active section of the Orange Book, including if there are no drugs in the active section of the Orange Book because any approved drugs have been discontinued from marketing and are reflected as such in the Orange Book, a product is eligible for designation as a CGT. So far out of approved 80 ANDAs, 70% products are filed against active prescription reference products, whereas 27% products are filed against discontinued products and 3% products are filed against active OTC products. The reference products are approved as old as 1946 till as new as approved in 2019.
                </div>
                <br/>
                <div style="width:100%">
                    <img src="assets/images/insights/insight5.JPG" class="image-center"/>
                </div>
                <mat-action-row>
                    <button mat-button color="warn" (click)="prevStep()">Previous</button>
                    <button mat-button color="primary" (click)="nextStep()">Next</button>
                </mat-action-row>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step === 5" (opened)="setStep(5)" hideToggle>
                <div style="width:100%">
                    <img src="assets/images/insights/insight6.JPG" class="image-center"/>
                </div>
                <br/>
                <div style="text-align: justify; text-indent: 25px;">
                    CGT applications are for those drugs, where there is inadequate generic competition for that drug. The term inadequate generic competition is defined to mean, with respect to a drug, that there is not more than one approved drug included in the active section of the Orange Book. This may either be the reference listed drug (RLD) or a drug approved in an ANDA referencing the same RLD as the drug for which designation as a CGT is sought. If there is not more than one such drug in the active section of the Orange Book, including if there are no drugs in the active section of the Orange Book because any approved drugs have been discontinued from marketing and are reflected as such in the Orange Book, a product is eligible for designation as a CGT. So far out of approved 80 ANDAs, 70% products are filed against active prescription reference products, whereas 27% products are filed against discontinued products and 3% products are filed against active OTC products. The reference products are approved as old as 1946 till as new as approved in 2019.
                </div>
                <br/>
                <div style="width:100%">
                    <img src="assets/images/insights/insight7.JPG" class="image-center"/>
                </div>
                <mat-action-row>
                    <button mat-button color="warn" (click)="prevStep()">Previous</button>
                    <button mat-button color="primary" (click)="end()">End</button>
                </mat-action-row>
            </mat-expansion-panel>

          </mat-accordion>
          
    </div>
</div>