import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  setMobileMenuPanel: boolean = false;
  showMobileMenuPanel: boolean = false;

  constructor(public _router: Router) {
    this.decideMenu();
  }

  windowResize(event) {
    this.decideMenu();
  }

  toggleMobileMenu() {
    this.showMobileMenuPanel = !this.showMobileMenuPanel;
  }

  decideMenu() {
    if(window.innerWidth*0.7 < 925) {
      this.setMobileMenuPanel = true;
    }
    else {
      this.setMobileMenuPanel = false;
    }
  }

  navigate(url: string) {
    this._router.navigateByUrl(url);
    this.toggleMobileMenu();
  }
}
