import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';


@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {

  slideNumber: number;
  timer: any;

  countries: any;

  keyFeatureMobileVersion: boolean = false;

  constructor(private _http:HttpClient) {
    this._http.get("assets/meta-data/countries.json").subscribe(data=>{
      this.countries = data;
    });
  }

  setKeyFeatureDisplayVersion() {
    if(window.innerWidth < 750) {
      this.keyFeatureMobileVersion = true;
    }
    else {
      this.keyFeatureMobileVersion = false;
    }
  }

  ngOnInit() {
    this.setColumnLayout();

    setTimeout(()=>{this.slideShow(0)},1000);
    this.timer = setInterval(()=>{
      this.slideShow(++this.slideNumber);
    }, 10000);
  }

  setHeaderFontSize() {
    for(let indx=0;indx<document.getElementsByTagName("h1").length;indx++) {
      if(window.innerWidth <= 700) {      
        document.getElementsByTagName("h1").item(indx).style.fontSize = "5vw";
      }
      else {
        document.getElementsByTagName("h1").item(indx).style.fontSize = "2em";
      }
    }      
    for(let indx=0;indx<document.getElementsByTagName("h2").length;indx++) {
      if(window.innerWidth <= 700) {      
        document.getElementsByTagName("h2").item(indx).style.fontSize = "3vw";
      }
      else {
        document.getElementsByTagName("h2").item(indx).style.fontSize = "1.5em";
      }
    }
    for(let indx=0;indx<document.getElementById("floater3").getElementsByTagName("h3").length;indx++) {
      if(window.innerWidth <= 700) {      
        document.getElementById("floater3").getElementsByTagName("h3").item(indx).style.fontSize = "3vw";
      }
      else {
        document.getElementById("floater3").getElementsByTagName("h3").item(indx).style.fontSize = "1.25em";
      }
    }
    
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }

  plusSlides(offset: number) {
    this.slideShow(this.slideNumber+offset);
  }

  slideShow(slideNo: number) {
    this.slideNumber = slideNo;

    let elements = document.getElementsByClassName("mySlides");
    for(let indx=0;indx<elements.length;indx++) {
      let slide: Element = elements[indx];
      (slide as HTMLElement).style.display="none";
    }
    if(this.slideNumber >= elements.length) {
      this.slideNumber = 0;
    }
    if(this.slideNumber < 0) {
      this.slideNumber = elements.length - 1;
    }
    let slide: Element = elements[this.slideNumber];
    (slide as HTMLElement).style.display="block";    

  }

  setColumnLayout() {
    let columnSize = Math.ceil(window.innerWidth * 0.75 / 250);

    document.getElementById("euCntryDiv").style.columnCount =  columnSize + "";    
    //document.getElementById("euCntryDiv").style.columnFill = "auto";
    document.getElementById("menaCntryDiv").style.columnCount =  columnSize + "";
    //document.getElementById("menaCntryDiv").style.columnFill = "auto";
    document.getElementById("latinCntryDiv").style.columnCount =  columnSize + "";
    //document.getElementById("latinCntryDiv").style.columnFill = "auto";
    document.getElementById("naCntryDiv").style.columnCount =  columnSize + "";
    //document.getElementById("naCntryDiv").style.columnFill = "auto";
    document.getElementById("asiaCntryDiv").style.columnCount =  columnSize + "";
    //document.getElementById("asiaCntryDiv").style.columnFill = "auto";
    document.getElementById("cisCntryDiv").style.columnCount =  columnSize  + "";
    //document.getElementById("cisCntryDiv").style.columnFill = "auto";
    
    this.setKeyFeatureDisplayVersion();
    this.setHeaderFontSize();
  }
  
}
