import { Component } from '@angular/core';
import { APP_CONFIG } from '../environment/Env';
import { HTTPService } from '../service/http-service';


@Component({
  selector: 'newsroom',
  templateUrl: './newsroom.component.html',
  styleUrls: ['./newsroom.component.css']
})
export class NewsRoomComponent {

  basePath: string;
  showNewsContent:boolean = false;

  constructor(private _http:HTTPService) {
    this.basePath = APP_CONFIG.adminAPIPath;
  }

  newsContents: any[] = [];
  ngOnInit() {
    this._http.get(this.basePath+"/public/newsRoom").subscribe((data:any[])=>{
      data.forEach(detail=>{
        detail["content"] = detail["content"].replace(/\r?\n|\r/g, "<br/>");
      });
      this.newsContents = data;
    });
  }

}