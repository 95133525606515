<div id="newsroom" style="min-height: calc(100vh - 175px);">
    <br/><br/>
    <div class="content-box">
        <div *ngFor="let details of newsContents">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>
                        {{details.title}}
                    </mat-card-title>
                    <mat-card-subtitle>
                        Published on: {{details.publishDate}}
                    </mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <img src="{{basePath}}/public/newsImage?id={{details.id}}" height="150px" width="270px"  style="float:right; margin: 0 0 0 5px">
                    <div  class="container" [class.show]="showNewsContent" [innerHTML]="details.content"></div>
                    <button mat-button (click)="showNewsContent = !showNewsContent">
                        {{ showNewsContent ? 'Show less': 'Show More' }}
                    </button>
                </mat-card-content>
            </mat-card>
            <br/><br/>
        </div>
    </div>
</div>