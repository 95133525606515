import { Component } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'insights1',
  templateUrl: './insight.component1.html',
  styleUrls: [ './insight.component1.css' ]
})
export class Insight1Component {
  step = 0;

  constructor(private _router: Router) {

  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  end() {
    this._router.navigateByUrl('/insight');
  }
}