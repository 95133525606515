import { Component } from '@angular/core';
import { APP_CONFIG } from '../environment/Env';
import { HTTPService } from '../service/http-service';
import { SharedService } from '../service/shared-service';


@Component({
  selector: 'insights',
  templateUrl: './insight.component.html',
  styleUrls: [ './insight.component.css' ]
})
export class InsightComponent {
  showPopUp: boolean;
  downloadFileName: string;
  message: string;

  constructor(private _http: HTTPService, private _sharedService: SharedService) {}
  
  initiateMarketSurvey(fileName) {
    this.downloadFileName = fileName;
    this.showPopUp = true;
  }

  emailDocument(metadata){
    this._http.getObjectData("assets/document/"+this.downloadFileName,null).subscribe((data:Blob)=>{
      this.showPopUp = false;
      this.postForEmail(data, metadata["email"], metadata["name"]);
    });
  }

  postForEmail(data,email,name) {
    this._sharedService.openSpinner();
    const fd = new FormData();
    fd.append("content", data);
    fd.append("email", email);
    fd.append("name", name);
    fd.append("fileName", this.downloadFileName);

    this._http.post(APP_CONFIG.adminAPIPath+"/public/insight-doc",fd).subscribe((response)=>{
      if(response["message"] == "SUCCESS") {
        this.message = "The requested document is sent to the email-id."
      }
      this._sharedService.closeSpinner();
    },
    (error)=> {
      this.message = "Failed submitting your request";
      this._sharedService.closeSpinner();
    });
  }
}