<div>
    <div class="slideshow-container">

        <div class="mySlides fade" style="display: block;">
        <img src="assets/images/floater/about_dq/image1.jpg" style="width:100%; height:400px;">
        </div>
        
        <div class="mySlides fade" style="text-align: center; background-color: #FFF;">
        <img src="assets/images/floater/about_dq/image2.jpg" style="width:75%; height:400px;">
        </div>
        
        <div class="mySlides fade">
        <img src="assets/images/floater/about_dq/image3.jpg" style="width:100%; height:400px;">
        </div>
        
        <a class="prev" (click)="plusSlides(-1)">&#10094;</a>
        <a class="next" (click)="plusSlides(1)">&#10095;</a>
        
    </div>
    <br>
        
    <div style="text-align:center">
        <span class="dot" (click)="slideShow(0)" [ngClass]="{'active' : slideNumber==0}"></span> 
        <span class="dot" (click)="slideShow(1)" [ngClass]="{'active' : slideNumber==1}"></span> 
        <span class="dot" (click)="slideShow(2)" [ngClass]="{'active' : slideNumber==2}"></span> 
    </div>
</div>
<br/>
<div class="content-box">
    <img src="assets/images/panel/panel1.jpg" height="220px" width="260px" style="float:left; margin: 0 15px 0 0 ">
    <div style="text-align: justify;">
        Drug Quester is specifically designed drug product database to fulfill the needs of various 
        professionals associated with pharmaceutical industry at a single source. It provides all sorts 
        of the information and analytics needed for decision making at various stages across 
        pharmaceutical domain. The data is being collected from hundreds of sources and combine, 
        normalize, analyze, and summarize to achieve meaningful data. 
        The analytics is one of its kind having more than 500 searching and refining parameters to 
        choose from, to achieve highly valuable informatics.
    </div>
    <br/>
    <div style="text-align: justify;">
        Drug Quester enables companies to understand and identify a product, API suppliers, new markets, 
        potential partners, innovator formulation, product launch strategy, drug class analytics and 
        competition. Drug Quester offers the most advanced analytics solutions for product portfolio, 
        business development, resource identification, launch calendar, budgeting and investment planning 
        from the industry authority on the global pharmaceutical market.
    </div>    
    <br/>
</div>
<br/><br/>
<div class="content-box">
    <img src="assets/images/panel/highlights.jpg" height="220px" width="260px" style="float:right; margin: 0 0 0 15px">
    <h3>A Few Highlights of Usage for Drug Quester</h3>
    <table width="60%">
        <tr>
            <td>
                <div class="content-note">
                    <div style="margin:10px;">
                        <ul>
                            <li class="highlights">Product Intelligence</li>
                            <li class="highlights">Key Patent Intelligence</li>
                            <li class="highlights">Market Intelligence</li>                    
                            <li class="highlights">Regulatory Intelligence</li>
                            <li class="highlights">Scientific Intelligence</li>
                        </ul> 
                    </div>
                </div>
            </td>
            <td>
                <div class="content-note">
                    <div style="margin:10px;">
                        <ul>
                            <li class="highlights">Competitive Intelligence</li>
                            <li class="highlights">Business Intelligence</li>                    
                            <li class="highlights">Litigation Intelligence</li>
                            <li class="highlights">Pipeline Intelligence</li>
                            <li class="highlights">Clinical Trial Intelligence</li>
                        </ul>
                    </div>                 
                </div>
            </td>
        </tr>
    </table>
</div>
<br/><br/>
<div class="content-box">
    <img src="assets/images/panel/team.jpg" height="220px" width="260px" style="float:left; margin: 0 15px 0 0">
    <h3>Our Team</h3>
    <div style="text-align: justify;">
        Quester represents a unique team of experts having advanced degree in science, pharmacy, 
        engineering, management, and law from prestigious institutes across the globe. 
        Team is having rich experience in data science, pharmaceutical industry, patent law, 
        regulatory science, financial modeling, forecasting, patent search and analysis, 
        clinical trials etc.
    </div>
</div>