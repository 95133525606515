<style>
    .form-panel {
        min-width: 300px;
        /*margin: 0 auto;*/
        width: 40%;
        background: #FFF;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 5px 5px 5px 5px rgba(71, 44, 44, 0.5);
    }
    .form-field-full-width {
        width: 100%;
    }
    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 20px;
        color: #333;
        cursor: pointer;
        background: none;
        border: none;
        outline: none;
    }
</style>
<div id="demo" style="min-height: calc(100vh - 175px);">
    <br/><br/>
        <!--<img src="assets/images/panel/demo.jpg" height="220px" width="300px" style="float:right; margin: 0 0 0 15px"> -->
        <h3 align="center">Tell us something about you. We will get back.</h3>        
        <div class="form-panel" style="margin: 0 auto;">
           
            <form class="example-form" [formGroup]="contactForm" (ngSubmit)="submit()" #formDirective="ngForm">
                <div style="text-align: right;">
                    <button class="btn-close "  (click)="goHome()">
                    </button>
                </div>

                
                <mat-form-field class="form-field-full-width">
                    <mat-label>Name</mat-label>
                    <input required matInput formControlName="name" placeholder="">
                    <mat-error *ngIf="contactForm.get('name').hasError('required')">
                        Name is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <br/>
                <mat-form-field class="form-field-full-width">
                    <mat-label>Email</mat-label>
                    <input type="email" required matInput formControlName="email" placeholder="Ex. pat@example.com">
                    <mat-error *ngIf="contactForm.get('email').hasError('email') && !contactForm.get('email').hasError('required')">
                        Please enter a valid email address
                    </mat-error>
                    <mat-error *ngIf="contactForm.get('email').hasError('required')">
                        Email is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
                <br/>
                <mat-form-field class="form-field-full-width">
                    <mat-label>Position</mat-label>
                    <input matInput formControlName="position" placeholder="">                    
                </mat-form-field>
                <br/>
                <mat-form-field class="form-field-full-width">
                    <mat-label>Department</mat-label>
                    <input matInput formControlName="department" placeholder="">                    
                </mat-form-field>
                <br/>
                <mat-form-field class="form-field-full-width">
                    <mat-label>Company</mat-label>
                    <input required matInput placeholder="" formControlName="company">                    
                </mat-form-field>
                <br/>
                <mat-form-field class="form-field-full-width">
                    <mat-label>Country</mat-label>
                    <mat-select required formControlName="country" [(value)]="countrycode">
                        <mat-option *ngFor="let country of countries" [value]="country.code">
                            {{country.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <br/>
                <mat-form-field class="form-field-full-width">
                    <mat-label>Contact No</mat-label>
                    <span matPrefix>{{countrycode}} &nbsp;</span>
                    <input required matInput placeholder="" formControlName="contactNo">
                </mat-form-field>
                <br/>
                <mat-form-field class="form-field-full-width">
                    <mat-label>Message</mat-label>
                    <textarea required maxlength="200" matInput formControlName="text" placeholder="" formControlName="text" #text></textarea>
                    <mat-error *ngIf="contactForm.get('text').hasError('required')">
                        Message Text is <strong>required</strong>
                    </mat-error>
                    <mat-hint align="end">{{text.value.length}} / 200</mat-hint>
                </mat-form-field>
                <br/>
                <button 
                    mat-raised-button 
                    class="form-field-full-width"
                    color="primary" 
                    [disabled]="contactForm.invalid"
                    type="submit"
                >Submit</button>
                
            </form>
            <h3 align="center" *ngIf="message != ''">{{message}}</h3>
        </div>        
</div>