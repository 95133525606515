<div id="insight" style="min-height: calc(100vh - 175px);">
    <br/><br/>
    <div class="content-box">        
        <h3 align="center">{{message}}</h3>
        <br/>
        <img src="assets/images/panel/insights.jpg" height="220px" width="300px" style="float:right; margin: 0 0 0 15px">
        <h3>Insights</h3>        
        <div>
            <a routerLink="/insight-cgt" routerLinkActive="active">
                <h4 style="margin: 0; padding: 0;">Competitive Generic Therapies (CGT)</h4>
            </a>
            A Regulatory Pathway for Unattractive Drug Products to Make it Attractive<br/>
            <span style="font-size: 12px;color: #7e7f80;">Published on: 14th April 2021,<a style="cursor: pointer;" (click)="initiateMarketSurvey('Competitive Generic Therapies (CGT).pdf')"><u>Download PDF</u></a></span>
        </div>
        <br/>
        <div style="text-align: justify;letter-spacing: 1px;">            
            In 2017, to bring more drug competition to the market and reduce cost of prescription, FDA announced the Drug Competition Action Plan (DCAP) to encourage robust and timely market competition for generic drugs and help bring greater efficiency and transparency to the generic drug review process, without sacrificing the scientific rigor underlying generic drug program. To remove barriers to generic drug development and market entry to spur competition so that consumers can get access to the medicines they need at affordable prices, FDA brings FDA Reauthorization Act of 2017 (FDARA). The FDA Reauthorization Act of 2017 (FDARA) provides a new pathway to for approval of generic products designated as “Competitive generic therapies” (CGT) for drugs for which there is “inadequate generic competition.” 
            <a routerLink="/insight-cgt" routerLinkActive="active">Read More</a> / <a style="cursor: pointer;" (click)="initiateMarketSurvey('Competitive Generic Therapies (CGT).pdf')"><u>Download Full Text</u></a>
        </div>
    </div>
</div>

<market-survey-popup 
    *ngIf="showPopUp"
    (cancelEvent)="showPopUp = !showPopUp"
    (successEvent)="emailDocument($event)"
></market-survey-popup>