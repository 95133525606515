<div id="career" style="min-height: calc(100vh - 175px);" (window:resize)="setPageSize()">
    <br/><br/>
    <div class="content-box">
        <img src="assets/images/panel/career.jpg" height="220px" width="300px" style="float:right; margin: 0 0 0 15px">
        <h3>Career</h3>
        <div style="text-align: justify;">
            Drug Quester is always on the lookout for people of passion and imagination to help create future. If you are looking for an ideal place for your career, then apply to Drug Quester at careers@drugquester.com.
        </div>
        <br/>
        <div style="text-align: justify;">
            At Drug Quester you can look ahead for a career that fosters professional growth and career advancement. We take a lot of care to structure the career progress of our members. With clients based across the globe, a global career awaits you at Drug Quester. 
        </div>
        <br/>
        <div style="text-align: justify;">
            We are looking for scientists, technologists, engineers, and patent agents to join the team at Drug Quester. If you have a zeal for high quality work and looking for a career in Intellectual Property, you are ready for Drug Quester. Drug Quester is always on the lookout for people of passion and imagination to help create future. 
        </div>
    </div>
    <br/><br/>
    <div class="content-box" *ngIf="details == undefined || details.length == 0">
        We do not have any open position as of now. We will update soon.
    </div>
    <div class="content-box" *ngIf="details!=undefined && details.length > 0">
        <h3>Current Openings</h3>
        <div style="width: 100%; display: flex;text-align: left;justify-content: center;">
            <div style="border-style: groove; border-radius: 15px; width:300px; height:200px;float: left; margin: 25px; position: relative;" *ngFor="let job of gridDetails">
                <h3 align="center">{{job.position}}</h3>
                <table>
                    <tr><th>Department</th><td>{{job.department}}</td></tr>
                    <tr><th>Experience</th><td>{{job.experience}}</td></tr>
                    <tr><th>Work Location</th><td>{{job.location}}</td></tr>
                </table>
                <div style="bottom:0;position: absolute; text-align: center; width:100%;">
                    <button mat-raised-button style="width:100%;" color="primary" (click)="showDescription(job.id)">Details</button>
                </div>                
            </div>
        </div>
        <mat-paginator [length]="pageLength" [pageSize]="pageSize" (page)="handlePageEvent($event)"></mat-paginator>
        <br/>
        <div *ngIf="jobDetail">
            <div class="public-content">
                <table>
                    <tr><td><b>Position</b></td><td><i>{{jobDetail.position}}</i><br/></td></tr>
                    <tr><td><b>Department</b></td><td><i>{{jobDetail.department}}</i><br/></td></tr>
                    <tr><td><b>Experience</b></td><td><i>{{jobDetail.experience}}</i><br/></td></tr>
                    <tr><td><b>Work Location</b></td><td><i>{{jobDetail.location}}</i><br/></td></tr>
                    <tr><td><b>Date Published</b></td><td><i>{{jobDetail.postingTime}}</i><br/></td></tr>
                </table>
                <br/><br/><b>Job Description</b><br/><br/>
                <div [innerHTML]="description"></div>
                <br/>
                <button mat-raised-button color="primary" (click)="apply()">Apply</button>
            </div>
        </div>
    </div>
</div>