<div id="signin" style="min-height: calc(100vh - 175px);" class="content-background">
    <br/><br/>
    <div>        
        <h3 style="text-align: center;">Welcome back to Drug Quester. Please provide your registered email id in to continue.</h3>
        <h4 style="text-align: center;">{{message}}</h4>
        <div class="login-panel">
            <form [formGroup]="recoveryForm">
                <mat-form-field class="form-field-full-width">
                    <mat-label>Email</mat-label>
                    <input type="email" required matInput formControlName="email" placeholder="Ex. pat@example.com">
                    <mat-error *ngIf="recoveryForm.get('email').hasError('email') && !recoveryForm.get('email').hasError('required')">
                        Please enter a valid email address
                    </mat-error>
                    <mat-error *ngIf="recoveryForm.get('email').hasError('required')">
                        Email is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            
                <br />
                
                <button mat-raised-button class="form-field-full-width" color="primary" [disabled]="recoveryForm.invalid" (click)="initiateRecovery()">Submit</button>
                
            </form>
        </div>
    </div>
</div>