<div id="signin" style="min-height: calc(100vh - 175px);" class="content-background">
    <br/><br/>
    <div>        
        <h3 style="text-align: center;">Welcome back to Drug Quester. Please provide your registered email id in to continue.</h3>
        <h4 style="text-align: center;" *ngIf="message != 'SUCCESS'">{{message}}</h4>
        <h4 style="text-align: center;" *ngIf="message == 'SUCCESS'">Password updated successfully. <a href="#/signin">Click here</a> to login</h4>
        <div class="login-panel" *ngIf="isValidToken">
            <form [formGroup]="recoveryForm">
                <mat-form-field class="form-field-full-width">
                    <mat-label>Email</mat-label>
                    <input type="email" readonly matInput formControlName="email" placeholder="Ex. pat@example.com">
                </mat-form-field>
                <br />
                <mat-form-field hintLabel="Minimum 8 Characters" class="form-field-full-width">
                    <mat-label>Password</mat-label>
                    <input matInput required #password formControlName="password" type="password">
                    <mat-hint align="end">{{password.value?.length || 0}}/8</mat-hint>
                    <mat-error *ngIf="recoveryForm.get('password').hasError('required')">
                        Password is required!
                    </mat-error>
                    <mat-error *ngIf="recoveryForm.get('password').hasError('minlength')">
                        Password should be of minimum 8 chars
                    </mat-error>
                </mat-form-field>
                <br/>
                <mat-form-field class="form-field-full-width">
                    <mat-label>Confirm Password</mat-label>
                    <input matInput required #confirm type="password" formControlName="confirmPassword" pattern="{{ password.value }}">       
                    <mat-error *ngIf="recoveryForm.get('confirmPassword').hasError('required')">
                        Confirm your password.
                    </mat-error>
                    <mat-error *ngIf="recoveryForm.get('confirmPassword').hasError('pattern')">
                        Password & Confirm password do not match.
                    </mat-error> 
                </mat-form-field>
                
                <button mat-raised-button class="form-field-full-width" color="primary" [disabled]="recoveryForm.invalid" (click)="updatePassword()">Submit</button>
                
            </form>
        </div>
    </div>
</div>