/*export const APP_CONFIG = {
    basePath: "http://localhost:8090",
    adminAPIPath: "http://localhost:8090",
    adminConsolePath: "http://localhost:4200/index.html",
    userConsolePath: "http://localhost:5000/index.html",
    docConsolePath: "http://localhost:4300/index.html"
}*/

export const APP_CONFIG = {
    basePath: "",
    adminAPIPath: "/drug-quester-admin",
    adminConsolePath: "/admin/index.html",
    userConsolePath: "/core/index.html",
    docConsolePath: "/doc/index.html"
}