import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_CONFIG } from '../environment/Env';
import { HTTPService } from '../service/http-service';
import { SharedService } from '../service/shared-service';

@Component({
  selector: 'job-application',
  templateUrl: './job-application.component.html',
  styleUrls: [
      './job-application.component.css'
  ]
})
export class JobApplicationComponent {

    jobId: string; 
    jobPosition: string;
    jobDepartment: string;
    message: string = '';
    
    constructor(
        private _route: ActivatedRoute,
        private _sharedService: SharedService, 
        private _formBuilder: FormBuilder,
        private _httpService: HTTPService
    ) {
        this._route.queryParams.subscribe(param=>{
            this.jobId = param["id"];
            this.jobDepartment = this._sharedService.jobDepartment;
            this.jobPosition = this._sharedService.jobPosition;
        });
    }

    fileToUpload: File;
    fileName: string;

    onClick() {
        const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
        fileUpload.onchange = () => {
            if(fileUpload.files.length > 0) {
                console.log(fileUpload.files[0].name);
                this.fileToUpload = fileUpload.files[0];
                this.fileName = this.fileToUpload.name;
                this.experienceGroup.get("file").setValue(this.fileName);
            }
        };
        fileUpload.click();
    }

    isLinear = true;
    personaDetailGroup: FormGroup;
    experienceGroup: FormGroup;
    questionaireGroup: FormGroup;
    resumeGroup: FormGroup;

    ngOnInit() {
        this.personaDetailGroup = this._formBuilder.group({
            firstName: [, { validators: [Validators.required], updateOn: "change" }],
            lastName: [, { validators: [Validators.required], updateOn: "change" }],
            email: [,{validators: [Validators.required, Validators.email], updateOn: "change",}],
            contact: [, { validators: [Validators.required], updateOn: 'change' }]
        });
        this.experienceGroup = this._formBuilder.group({
            website: [, { updateOn: "change" }],
            experience: [, { validators: [Validators.required], updateOn: "change" }],
            file: [, { validators: [Validators.required], updateOn: "change" }]
        });
        this.questionaireGroup = this._formBuilder.group({
            ctc: [, { validators: [Validators.required], updateOn: 'change' }],
            startDate: [, { validators: [Validators.required], updateOn: 'change' }],
            relocation: [, { validators: [Validators.required], updateOn: "change" }],
            company: [, { validators: [Validators.required], updateOn: "change" }],
            comment: [, null],
        });
    }

    onSubmit() {
        this._sharedService.openSpinner();
        const fd = new FormData();
        fd.append("jobId", this.jobId);
        fd.append("position", this.jobPosition);
        fd.append("firstName", this.personaDetailGroup.get("firstName").value);
        fd.append("lastName", this.personaDetailGroup.get("lastName").value);
        fd.append("email", this.personaDetailGroup.get("email").value);
        fd.append("contact", "+91"+this.personaDetailGroup.get("contact").value);
        fd.append("website", this.experienceGroup.get("website").value);
        fd.append("experience", this.experienceGroup.get("experience").value);
        fd.append("fileName", this.experienceGroup.get("file").value);
        fd.append("file", this.fileToUpload);
        fd.append("ctc", this.questionaireGroup.get("ctc").value);
        fd.append("startDate", this._sharedService.formatDate(this.questionaireGroup.get("startDate").value));
        fd.append("relocation", this.questionaireGroup.get("relocation").value);
        fd.append("company", this.questionaireGroup.get("company").value);
        if(this.questionaireGroup.get("comment").value == null) {
            this.questionaireGroup.get("comment").setValue(" ");
        }
        fd.append("comment", this.questionaireGroup.get("comment").value);
        
        this._httpService.post(APP_CONFIG.adminAPIPath+"/public/jobApplication",fd).subscribe(data=>{
            this.message = data["message"];
            this._sharedService.closeSpinner();
        },
        error=>{
            this.message = "Failed to submit job application."
            this._sharedService.closeSpinner();
        });        
    }
    
}