<br/><br/>
<div class="content-box">
    <h3>DRUG QUESTER SUBSCRIPTION AGREEMENT</h3>
    <div style="text-align: justify;">
        <br/>
        <b>Please read the Terms and Conditions. By receiving a username and password, and by logging in as a “Member,” you certify that you have read and agree to abide by all such Terms and Conditions and that you are authorized to agree and abide by all such Terms and Conditions on behalf of you as individual, your company or institution (hereafter referred to as “Subscriber”).</b>
        <br/><br/>By this Agreement, <b>Drug Quester Private Limited. (“Drug Quester Private Limited”)</b>, SYNO 208/E, CHANDANAGAR, MIYAPUR, HYDERABAD, TELANGANA-500050, INDIA, grants a license to the Subscriber to access the subscriber-only sections of Drug Quester on the Internet from the number of sites (as defined below) specified in Section 4 of this Agreement, subject to the terms and conditions set forth in the following paragraphs, all of which are hereby agreed to between Drug Quester Private Limited and the Subscriber. Access to and use of the Drug Quester is conditioned upon the Subscriber acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who wish to access or use the Drug Quester.
        <br/><br/>
    </div>
</div>
<br/><br/>
<div class="content-box">
    <h3>Terms and Conditions</h3>
    <ol>
        <li>
            <div>
                <div>
                    <b>Drug Quester.</b> Drug Quester is an online compilation of public records, information from public records, and original articles (collectively, “publications”). Drug Quester Private Limited reserves the right to add or delete Drug Quester content at any time.
                    <br/><br/>
                </div>
            </div>    
        </li>
        <li>
            <div>
                <div>
                    <b>Scope of License; Institutional Subscriber Use Restrictions.</b> The subscription includes authorization by means of the Subscriber’s IP address to allow site-wide online access by authorized users via multiple connections. If a proxy server is being registered for access, the Subscriber agrees to maintain the security of the access by authenticating authorized users before allowing use of the proxy to any remote or on-site user. Authorized users must be employees, staff, or (for academic Subscribers) faculty or students, officially affiliated with the Subscriber, or authorized on-site patrons of the Subscriber’s library facilities.
                    <br/><br/>
                    Sites may be academic or nonacademic. For academic Subscribers, different campuses are considered different sites even if they are within the same city. Departments or libraries of the same campus are considered part of the same site. For nonacademic Subscribers, sites are defined as geographically separate units, even if they are within the same city. Each branch, office, or laboratory is a different site.
                    This subscription entitles the Subscriber and authorized users to access all portions of Drug Quester and:
                    <br/><br/>
                    <ol type="a">
                        <li>
                            make searches of Drug Quester
                        </li>
                        <li>
                            download search results to paper, hard disk, or diskette, provided that such data is not made available to anyone who is not an authorized user. Authorized users who do download search results must maintain all copyright and other notices.                        
                        </li>
                    </ol>
                    <br/>Systematic downloading of substantial portions of the database and use of robots is prohibited.
                    <br/><br/>
                    Academic and public library Subscribers may use hard copies derived from the electronic edition of the publications for the purpose of inter-library loan with the same limitations that apply to copies for that purpose made from the print or online versions of the publications. Specifically, copies must be made in compliance with Indian Copyright Act. Authorized users who do print items must maintain all copyright and other notices on the printed items.
                    <br/><br/>
                    A Subscriber or authorized user may not make Drug Quester available to anyone other than another authorized user, whether by telephone link, Internet connection, or by permitting access through his or her terminal or computer, or by other similar or dissimilar means or arrangements.
                    <br/><br/>
                    Subscribers will make reasonable efforts to maintain security and monitor all use (including unauthorized use) from their sites and to communicate the terms of the license to their users.
                    <br/><br/>
                </div>
            </div>
        </li>
        <li>
            <div>
                <div>
                    <b>Access.</b> Drug Quester access is granted site-wide only for those users specifically agreed upon in writing by Confirmation E-mail. Subscribers may request access for any number of users; however, usage must be restricted to a single site, or such larger number of sites as may be expressly stated in the subscription agreement.
                    <br/><br/>
                </div>
            </div>
        </li>
        <li>
            <div>
                <div>
                    <b>Term and Fees.</b> The annual subscription fee shall be as agreed upon in writing by Confirmation E-mail. The fee shall be payable within 30 (thirty) days after receipt of invoice. Subsequent annual subscription fees shall be invoiced by Drug Quester Private Limited and shall be due and payable within 30 (thirty) days of receipt.
                    <br/><br/>
                    Subscriber and its authorized users must use the assigned usernames and passwords in order access to the Subscriber-only portions of Drug Quester.
                    <br/><br/>
                    The term and each renewal term of a subscription will last through one year after the term begins (the “Renewal Date”). The subscription will renew thereafter for successive one-year terms unless Subscriber cancels the subscription by providing at least thirty (30) days written notice before the Renewal Date of such cancellation.
                    <br/><br/>
                    The annual fee for this subscription may be raised by written notice to the Subscriber, given at least sixty 60 days prior to the next succeeding Renewal Date. Subscriber may terminate a subscription, effective on the next Renewal Date, by at least 30 days written notice to Drug Quester Private Limited, which may be given by e-mail through the Customer Service link on the Drug Quester website. The Subscriber will be liable for the subscription fee for the upcoming renewal term if it fails to give notice of termination at least 30 days before the Renewal Date.
                    <br/><br/>
                    Payment in full for a renewal term is due within thirty (30) days after receipt of invoice which shall be sent to Subscriber on or before the Renewal Date. There is no grace period, and access to Drug Quester will terminate after the Renewal Date until payment is made. Late payment of the full year’s subscription fee will reinstate the subscription through the end of the calendar year in which the payment is made.
                    <br/><br/>
                    Except when required by law, paid Subscription fees are non-refundable.
                    <br/><br/>
                </div>
            </div>
        </li>
        <li>
            <div>
                <div>
                    <b>Support.</b> Drug Quester includes online documentation. Drug Quester Private Limited does not provide user support via e-mail, telephone, or otherwise.
                    <br/><br/>
                </div>
            </div>
        </li>
        <li>
            <div>
                <div>
                    <b>Copyright and Violations of Contract.</b> Drug Quester and its contents are subject to copyright, database protection, and other rights of the publisher (Drug Quester Private Limited), under the laws of India and the country of use.
                    <br/><br/>
                    The Subscriber acknowledges that it has no claim to ownership by reason of its use of or access to Drug Quester. Downloading of data is permitted solely for Subscriber’s internal use to the same extent as the printed version of the publication. Recompiling, publication or republication of the data, or any portion thereof, in any form or medium whatsoever, may be done only with specific written permission from Drug Quester Private Limited
                    <br/><br/>
                    Resale of data downloaded from the database, whether such resale be in the form of sale, subscription, or otherwise, whether the format or appearance of the data be similar to or different from its appearance on Drug Quester, and whether the data be in printed, electronic, or other form, is prohibited. Violators of any of the restrictions set forth in this paragraph shall be liable either for copyright infringement or, to the extent that the downloaded data are not protected by copyright laws, for damages for breach of contract and/or trespass in the amount of (a) actual damages or (b) liquidated damages calculated as if the data in question were protected by the copyright laws, whichever may be greater, together with Drug Quester Private Limited’ reasonable attorneys’ fees and other expenses in pursuing such remedies.
                    <br/><br/>
                </div>
            </div>
        </li>
        <li>
            <div>
                <div>
                    <b>Suspension or termination of access.</b> Drug Quester Private Limited reserves the right to suspend and/or terminate access to Drug Quester immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the any term of this Agreement and shall not be liable to Subscriber for any portion or refund of the subscription fee. Any use of the Drug Quester database beyond the scope of the License or any fraudulent, abusive, or otherwise illegal activities may be grounds for termination of access. The foregoing includes but is not limited to activity that may burden Drug Quester Private Limited’ server(s), such as computer programs that automatically download content, commonly known as robots, spiders, crawlers, wanderers or accelerators.
                    <br/><br/>
                    All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
                    <br/><br/>
                </div>
            </div>
        </li>
        <li>
            <div>
                <div>
                    <b>Disclaimer of warranties.</b>
                    <ol type="a">
                        <li>
                            Drug Quester Private Limited has made and will make good faith efforts to ensure that Drug Quester is complete and accurate. However, Drug Quester Private Limited does not warrant completeness or accuracy and does not warrant that the Subscriber’s use of Drug Quester will be uninterrupted or error-free, or that the results obtained will be useful or will satisfy the Subscriber’s requirements.
                        </li>
                        <li>
                            Drug Quester Private Limited warrants that it is entitled to grant the licenses outlined in this Agreement, <b>BUT MAKES NO OTHER WARRANTIES OR REPRESENTATIONS OF ANY KIND, EXPRESSED OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NONINFRINGEMENT, OR PREVENTION OF HACKING. </b>
                        </li>
                        <li>
                            The Subscriber will be permitted to access Drug Quester at any time while a subscription term is in effect. Drug Quester Private Limited will not be liable for any delay, down time, or other failure of performance, but will use reasonable efforts to correct any performance problem brought to its attention.
                        </li>
                        <li>
                            Drug Quester Private Limited will not be responsible for incidental, consequential, or any other damages arising out of or in connection with the service or materials provided hereunder.
                        </li>
                        <li>
                            The Subscriber assumes the sole responsibility for all use of Drug Quester and agrees to indemnify and hold Drug Quester Private Limited harmless from any liability or claim of any person arising from such use. The Subscriber agree to defend, indemnify and hold harmless Drug Quester Private Limited and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) its use and access of the database, by it or any person using its account and password, or b) a breach of these Terms.                    
                        </li>
                    </ol>
                    <br/>
                </div>
            </div>
        </li>
        <li>
            <div>
                <div>
                    <b>Privacy Policy.</b> Your privacy and the confidentiality of your use of Drug Quester are important to us. Drug Quester Private Limited does not: (a) collect any personal information of Subscribers except that which the Subscriber furnishes to Drug Quester Private Limited in the course of subscribing or communicating with Drug Quester Private Limited; (b) use any information it may collect from Subscribers except for billing or other administrative purposes; (c) sell or otherwise transfer any such information to any third party except if it is legally required to do so such as by subpoena or court order, or in the event that there is a change in its ownership or corporate structure; or (d) intentionally monitor or track the browsing patterns of Subscribers or usage of the website by Subscribers, except if it has reason to suspect that a Subscriber or user may be violating the terms and conditions of usage of the site. The site will place cookies on the user’s computers for user log-in convenience only and is subject to the above terms.
                    <br/><br/>
                </div>
            </div>
        </li>
        <li>
            <div>
                <div>
                    <b>General.</b>
                    <ol type="a">
                        <li>
                            The obligations of the Subscriber under this Agreement or under the law with respect to copyright and other protections of the publications or any other intellectual property that is part of or associated with Drug Quester or Drug Quester content, including but not limited to the obligations set forth in Section 6 of this Agreement, shall survive the termination or expiration of any subscription term.
                        </li>
                        <li>
                            This Agreement including Confirmation E-mail constitutes the entire agreement of the parties and supersedes any prior communication between the parties with respect to the subject matter hereof. Only a written instrument signed by both parties may amend it; electronic signatures are acceptable when agreed to by both parties. Without limitation of the foregoing, any purchase order or other instrument that the Subscriber may issue for Drug Quester is for the Subscriber’s internal purposes only and will in no way modify, add to or subtract from the terms and conditions provided herein.
                        </li>
                        <li>
                            The Subscriber may not assign or transfer its rights under this Agreement unless to a successor organization.
                        </li>
                        <li>
                            This Agreement will be governed by the Laws of India. However, any action arising out of or relating to this Agreement or Drug Quester may be brought in courts situated in Hyderabad, Telangana, India, and the parties consent to the jurisdiction of such courts.
                        </li>
                        <li>
                            Failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between Drug Quester Private Limited and the Subscriber and supersede and replace any prior agreements between the parties.
                        </li>
                        <li>
                            Drug Quester Private Limited reserve the right, at its sole discretion, to modify or replace these Terms at any time at its sole discretion. By continuing to access or use Drug Quester after any revisions become effective, the subscriber agrees to be bound by the revised terms. 
                        </li>
                    </ol>
                    <br/>
                </div>
            </div>
        </li>
        <li>
            <div>
                <b>Contact Us.</b>If you have any questions about these Terms, please contact us.
                <br/><br/>                
            </div>
        </li>
    </ol>
</div>
<br/><br/>