<div>
    <div class="mapouter">
        <div class="gmap_canvas">
            <iframe width="100%" height="300px" id="gmap_canvas" src="https://maps.google.com/maps?q=Solitaire%20Abode,%20Chanda%20Nagar&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>            
        </div>
    </div>
</div>
<br/><br/>
<div class="content-box">
    <div style="float:left;">
        <h3>Contact Us</h3>
        <div style="font-size: 20px; letter-spacing: 1px;">
            DRUG QUESTER PRIVATE LIMITED<br/>
            SY NO 208/E, Chandanagar, Miyapur<br/>
            Hyderabad, Telangana, India-500050<br/>
            Tel: + 91 799 573 2490<br/>
            Email: <a href="mailto:info@drugquester.com">info@drugquester.com</a><br/>
        </div>
        <br/><br/><br/>
    </div>  
    <div class="form-panel" style="float:right;">
        <form class="example-form" [formGroup]="contactForm" (ngSubmit)="submit()" #formDirective="ngForm">
            <mat-form-field class="form-field-full-width">
                <mat-label>Name</mat-label>
                <input required matInput formControlName="name" placeholder="">
                <mat-error *ngIf="contactForm.get('name').hasError('required')">
                    Name is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <br/>
            <mat-form-field class="form-field-full-width">
                <mat-label>Email</mat-label>
                <input type="email" required matInput formControlName="email" placeholder="Ex. pat@example.com">
                <mat-error *ngIf="contactForm.get('email').hasError('email') && !contactForm.get('email').hasError('required')">
                    Please enter a valid email address
                </mat-error>
                <mat-error *ngIf="contactForm.get('email').hasError('required')">
                    Email is <strong>required</strong>
                </mat-error>
            </mat-form-field>
            <br/>
            <mat-form-field class="form-field-full-width">
                <mat-label>Company</mat-label>
                <input required matInput formControlName="company">                    
            </mat-form-field>
            <br/>
            <mat-form-field class="form-field-full-width">
                <mat-label>Country</mat-label>
                <mat-select required formControlName="country" [(value)]="countrycode">
                    <mat-option *ngFor="let country of countries" [value]="country.code">
                        {{country.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <br/>
            <mat-form-field class="form-field-full-width">
                <mat-label>Contact No</mat-label>
                <span matPrefix>{{countrycode}} &nbsp;</span>
                <input required type="tel" matInput formControlName="contact" placeholder="555-555-1234">                    
            </mat-form-field>
            <br/>
            <mat-form-field class="form-field-full-width">
                <mat-label>Message</mat-label>
                <textarea required maxlength="200" matInput formControlName="text" placeholder="" #text></textarea>
                <mat-error *ngIf="contactForm.get('text').hasError('required')">
                    Message Text is <strong>required</strong>
                </mat-error>
                <mat-hint align="end">{{text.value.length}} / 200</mat-hint>
            </mat-form-field>
            <br/>
            <button 
                mat-raised-button 
                class="form-field-full-width" 
                color="primary" 
                [disabled]="contactForm.invalid"
                type="submit"
            >
                Submit
            </button>            
        </form>
        <h3 align="center" *ngIf="message != ''">{{message}}</h3>
    </div>
    <!--<img src="assets/images/panel/contact.jpg" height="220px" width="300px" style="float:right; margin: 0 0 0 15px">-->  
</div>
