<br/><br/>
<div class="content-box">
    <h3>PRIVACY AND COOKIES</h3>
    <div class="content-pane-lightblue">
        <div class="public-content">
            <b>
            Please read the privacy and cookies. By receiving visiting drug quester private limited websites, you certify that you have read and agree to abide by our privacy and cookies policy and that you are authorized to agree and abide by all such privacy and cookies policies on behalf of you as individual, your company or institution (hereafter referred to as “Subscriber”).
            </b><br/><br/>
        </div>
    </div>
    <div class="content-pane-lightblue">
        <div class="public-content">
            <b>Drug Quester Private Limited. (“Drug Quester Private Limited”)</b>, SYNO 208/E, CHANDANAGAR, MIYAPUR, HYDERABAD, TELANGANA-500050, INDIA, provides websites to be used and a database of information which is an online compilation of public records, information from public records, and original articles (collectively, “publications”). Drug Quester Private Limited reserves the right to add or delete Drug Quester content at any time. Access to and use of the Drug Quester websites is conditioned upon the user acceptance of and compliance with our privacy and cookies policy. This policy applies to all visitors, users and others who wish to access or use the Drug Quester private limited websites or its products.
            <br/><br/>
        </div>
    </div>
</div>
<br/><br/>
<div class="content-box">
    <ol>
        <li>
            <div class="content-pane-lightblue">
                <div class="public-content">
                    <b>Drug Quester Private Limited</b> takes the Privacy Policy issues seriously. The statements below explain Drug Quester Private Limited privacy practices, personal or corporate data protection practices, goals and methods of usage of this data, as well as data security and storage. Accepting the Privacy Policy is equal to consent to personal or company data saving and processing, as well as to receiving marketing or commercial communication according to the Privacy Policy.
                    <br/><br/>
                </div>
            </div>
        </li>
        <li>
            <div class="content-pane-lightblue">
                <div class="public-content">
                    <b>Updates to this Privacy Policy</b> - this Privacy Policy may change from time to time without notice. The binding version of this Privacy Policy will be posted on the Drug Quester Private Limited websites, so all users are always aware of what data is collected and how it is used.
                    <br/><br/>
                </div>
            </div>
        </li>
        <li>
            <div class="content-pane-lightblue">
                <div class="public-content">
                    <b>The information collected:</b>
                    <ol type="I">
                        <li>
                            <b>Information gathered automatically such as: </b>
                            <ol type="a">
                                <li>identification of telecommunications network end or telecommunications system that was used by the user;</li>
                                <li>information on starting, finishing and scope of each use of Services or Products, Drug Quester Private Limited websites and the Shop;</li>
                                <li>the user’s internet protocol (IP) which the user uses to connect to the Internet;</li>
                                <li>the user’s browser type and version; operating system type and version;</li>
                                <li>the URL address that referred the user to Drug Quester Private Limited website;</li>
                                <li>information about user demographics, localization, interests and behavior on the website;</li>
                                <li>
                                    information on the user’s transaction status when the user purchases via the <b>Drug Quester Private Limited</b> online shop or requests free materials via online forms.
                                    This information is collected through e.g. the use of cookies (certain bits of information stored on the hard drive of the user’s computer when he visits <b>Drug Quester Private Limited</b> websites; they are used for example to maintain session information, customize website, customize adverts published on other websites and create statistics; <b>Drug Quester Private Limited</b> websites may not function correctly if the user disables them).                            
                                </li>
                            </ol>
                            <br/>
                        </li>
                        <li>
                            <b>Information the user provides by:</b>
                            <ol type="a">
                                <li>filling in Drug Quester Private Limited contact, registration, order or other forms;</li>
                                <li>submitting a question about products and/or services via Drug Quester Private Limited websites;</li>
                                <li>requesting more detailed information about products and/or services;</li>
                                <li>buying on Drug Quester Private Limited website and providing personal and company details, address and other data;</li>
                                <li>subscribing to newsletters;</li>
                                <li>requesting or subscribing to other materials (e.g. articles, market news), promotional or informational alerts and communication;</li>
                                <li>leaving comments or messages on the Drug Quester Private Limited websites or Drug Quester Private Limited forums, chats or bulletin boards.</li>
                            </ol>
                            <br/>
                        </li>
                        <li>
                            Registering and filling in forms on Drug Quester Private Limited websites are optional. By choosing not to register or not to provide personal information, the user can still use the websites. They may not, however, be able to purchase products or receive certain free materials.
                            <br/><br/>
                        </li>
                        <li>
                            The examples of personal or company information that Drug Quester Private Limited collects are: e-mail, salutation, first name, last name, job title, company, www address, core business, address, city, zip code, country, telephone number, sectors of interest.
                            <br/><br/>
                        </li>
                        <li>
                            <b>Google and other providers of cookies:</b>
                            <ol>
                                <li>Other providers, including Google, are displaying <b>Drug Quester Private Limited</b> advertisements on websites.</li>
                                <li>Google and other providers use cookies for displaying advertisements to internet viewers based on the record of their previous visits on a given website, based on their interests, demographic data or localization.</li>
                                <li>It is possible to resign from using Google cookies by following the instruction posted on Google Advertising site: www.google.com/settings/ads. It is also possible to resign from using cookies of other providers, following the instruction on Network Advertising Initiative site: www.networkadvertising.org/choices</li>
                                <li><b>Drug Quester Private Limited</b> websites are connected with 3rd party analytic tools (such as google analytics), which is a tool for analyzing website visits and which is provided by Google, Inc. (henceforth Google). Google Analytics uses cookies (a small snippet of text that a website requires your browser to store) for the purpose of analyzing website visits. Information generated by cookies on the website visits (and about the user’s IP) are delivered to Google and saved by Google on servers in the United States of America. Google uses the information to assess the degree of website use, to create reports regarding website traffic for website administrators and to provide other services connected with web traffic and internet use. Google may also share the information with third parties if it is required by law or if other parties process the information for Google. Google does not connect the visitor’s IP address with any other data stored by Google. You may resign from cookies by setting appropriate internet browser preferences. By visiting Drug Quester Private Limited websites, the visitor thereby accepts the above-described manner in which data are used by Google.
                            </ol>    
                        </li>
                    </ol>
                    <br/><br/>
                </div>
            </div>
        </li>
        <li>
            <div class="content-pane-lightblue">
                <div class="public-content">
                    <b>Goals of collecting data, methods of using them and security of the information - 
                    Drug Quester Private Limited</b> uses the information collected automatically and provided by users to maintain the session; customize website to preferences and tools used; diagnose problems; create statistics; improve products; services and Drug Quester Private Limited websites; customize adverts displayed on website, in Google search results, in other advertising networks or on other websites; provide the users with products, services and/or other materials the users have requested or purchased; and to inform them about additional products, services and other materials that may be of interest to them, using different methods of communication. Information collected from and about the users by Drug Quester Private Limited will not be disclosed to any other company or third parties, other than those controlled by Drug Quester Private Limited management and/or shareholders, other than those necessary to achieve goals described in this Privacy Policy, and other than those required by law. Drug Quester Private Limited maintains a data protection policy which means that we will neither use the users’ personal or company information nor sell, trade, rent or lease it to others without the users’ consent. Drug Quester Private Limited cares about the safety of Drug Quester Private Limited Clients' personal data. Drug Quester Private Limited protects the Clients' data against unauthorized parties.
                    <br/><br/>
                </div>
            </div>
        </li>
        <li>
            <div class="content-pane-lightblue">
                <div class="public-content">
                    <b>Free materials and subscriptions</b> - the newsletters and other marketing, informative or promotional materials that the user may download/subscribe to on the <b>Drug Quester Private Limited</b> websites is a double opt-in offer. The user downloads/subscribes to those by explicitly providing <b>Drug Quester Private Limited</b> with the user’s e-mail address and additional information on the forms designed for that purpose.
                    <br/><br/>
                </div>
            </div>
        </li>
        <li>
            <div class="content-pane-lightblue">
                <div class="public-content">
                    <b>Buying in Drug Quester Private Limited Online Shop </b>- buying in <b>Drug Quester Private Limited</b> online shop is a two-stage process.
                    <ol type="I">
                        <li>
                            After choosing the products that the user would like to buy, the user provides Drug Quester Private Limited with all the information necessary to process the order, except for payment information (i.e., the user’s credit card details) which are not stored in the shop. All the order information is transmitted over the Internet using a secure SSL connection. Drug Quester Private Limited protects the security of the user’s personal information from unauthorized access.
                        </li>
                        <li>
                            If the user decides to pay online with the credit card, the user is redirected, also via an SSL secure connection, to the website of the professional secure Internet credit card authorization center. Only there does the user give the credit card number and other necessary details. Drug Quester Private Limited does not have access to nor stores any of the information. The user can thus be sure that his most sensitive payment data will remain totally confidential. Any questions regarding payment procedures and order placement can be answered by the Drug Quester Private Limited Sales Department (from Monday to Friday, 9:00 am – 6:00 pm IST on + 91 7995732490 or at sales@drugquester.com).
                        </li>
                    </ol>
                    <br/>
                </div>
            </div>
        </li>
        <li>
            <div class="content-pane-lightblue">
                <div class="public-content">
                    <b>Unsubscribing and access to the data</b> - the user has right to access and change his personal data and corporate information; the user also may unsubscribe at any time from any of the newsletters, marketing, commercial and promotional materials and other communication. If the user does not wish to continue any communication with <b>Drug Quester Private Limited</b>, is requested to notify <b>Drug Quester Private Limited</b> by sending an e-mail with such request to info@drugquester.com; If the user does not wish to continue receiving e-mailings or <b>Drug Quester Private Limited</b> subscriptions only, is requested to notify Drug Quester Private Limited by using the unsubscribe link included in each e-mailing or e-newsletter from Drug Quester Private Limited or by sending an e-mail entitled “UNSUBSCRIBE” to info@ drugquester.com.
                    <br/><br/>
                </div>
            </div>
        </li>
        <li>
            <div class="content-pane-lightblue">
                <div class="public-content">
                    <b>Limitations of liability - Drug Quester Private Limited</b> is not responsible for any errors and omissions or the results obtained from the use of information presented in <b>Drug Quester Private Limited</b> products, services, news, analyses, on <b>Drug Quester Private Limited</b> websites and in other materials. Drug Quester Private Limited guarantees that the information is based on the analysis and research carried out by Drug Quester Private Limited alone and based on the independent and reliable sources of data. Drug Quester Private Limited websites include links to other websites and <b>Drug Quester Private Limited</b> is not responsible for any actions of third parties managing or owning these websites.
                    <br/><br/>
                </div>
            </div>
        </li>
    </ol>
</div>    
<br/><br/>