import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { APP_CONFIG } from '../environment/Env';
import { SharedService } from '../service/shared-service';


@Component({
  selector: 'career',
  templateUrl: './career.component.html'
})
export class CareerComponent {

    details: any[];
    gridDetails: any[];
    description: string = "";
    jobDetail: any;

    constructor(
        private _httpClient: HttpClient,
        private _sharedService: SharedService,
        private _router: Router
    ) {
        this._sharedService.openSpinner();
        this._httpClient.get(APP_CONFIG.adminAPIPath+"/public/jobPosting").subscribe(data=>{
            this.details = <any[]>data;
            this.pageLength = this.details.length;
            this.gridDetails = [];
            this.setGridData(this.details.length, this.pageSize, 0);            
            this.setPageSize();
            this._sharedService.closeSpinner();
        });
    }

    setPageSize() {
        this.pageSize = Math.floor(window.innerWidth / 400);
        this.pageSize = (this.pageSize == 0 ? 1 : this.pageSize);
        this.gridDetails = [];
        this.setGridData(this.details.length, this.pageSize, 0);            
    }

    private setGridData(totalLength: number, pageSize: number, startIndex: number) {
        let jndx = 0;
        for(let indx = startIndex; indx < (startIndex + (totalLength > pageSize ? pageSize : totalLength)); indx++) {
            this.gridDetails[jndx] = this.details[indx];
            jndx++;
        }        
    }

    pageLength: number; 
    pageSize: number = 3;

    showDescription(idInput) {
        this._sharedService.openSpinner();
        this.details.forEach(element => {
            if(element.id==idInput) {
                this.jobDetail = element;
                this._httpClient.get(APP_CONFIG.adminAPIPath+"/public/jobDetail?jobId="+this.jobDetail.id)
                .subscribe((data)=> {
                    this.description = data["description"];                    
                    this._sharedService.closeSpinner();
                })
            }
        });
    }

    handlePageEvent(event: PageEvent) {        
        this.gridDetails = [];
        let remainingLength =  ((event.pageIndex + 1) * event.pageSize < event.length ? event.pageSize: (event.length - event.pageIndex * event.pageSize));
        this.setGridData(remainingLength, event.pageSize, event.pageIndex * event.pageSize);        
    }

    apply() {
        this._sharedService.jobPosition = this.jobDetail["position"];
        this._sharedService.jobDepartment = this.jobDetail["department"];
        this._router.navigateByUrl("job-application?id="+this.jobDetail["id"]);
    }
}