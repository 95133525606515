import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { APP_CONFIG } from '../environment/Env';
import { SharedService } from '../service/shared-service';


@Component({
  selector: 'subscription-popup',
  templateUrl: './subscription-popup.component.html',
  styleUrls: ['./subscription-popup.component.css']
})
export class SubscriptionPopUpComponent {

  countries: any[] = [];
  countrycode: string = "";
  presentCountry: string = "";

  @ViewChild('formDirective') private formDirective: NgForm;
  
  constructor(private _formBuilder: FormBuilder, private _http: HttpClient, private _sharedService: SharedService) {

    this._http.get("assets/meta-data/country-list.json").subscribe((data:any[])=>{
      this.countries = data;
    });
  }

  contactForm: FormGroup = this._formBuilder.group({
    email: [,{validators: [Validators.required, Validators.email], updateOn: "change"}],
    name: [, { validators: [Validators.required], updateOn: "change" }],
    text: [, { validators: [Validators.required], updateOn: "change" }],
    country: [, null],
    contact: [, null],
    company: [, null],
    frndemail: [,null],
    frndname: [, null],
  });

  @Input() showQuoteRequestPanel: boolean;
  @Input() showForwardRequestPanel:boolean = false;


  message: string;

  @Input() flag: string;
  
  @Output() cancelEvent: EventEmitter<any> = new EventEmitter();
  cancel() {
    this.cancelEvent.emit(true);
  }

  submit() {
    let data = {};
    data["flag"]=this.flag;
    data["name"] = this.contactForm.get("name").value;
    data["email"] = this.contactForm.get("email").value;
    data["query"] = this.contactForm.get("text").value;
    
    if(this.flag != "FF") {
      data["contact"] = this.contactForm.get("country").value+""+this.contactForm.get("contact").value;
      data["company"] = this.contactForm.get("company").value;
      this.countries.forEach(element => {
        if(element["code"] == this.countrycode) {
          data["country"] = element["name"];
        }
      });
    }

    if(this.flag == "FF") {
      data["forwardName"] = this.contactForm.get("frndname").value;
      data["forwardEmail"] = this.contactForm.get("frndemail").value;
    }
    
    this._sharedService.openSpinner();
    this._http.post(APP_CONFIG.adminAPIPath+"/public/customerQueries",data).subscribe(data=>{
        if(data["message"] == "SUCCESS") {
          if(this.flag == 'FF') {
            this.message = "Thank you for sharing the details."
          }
          else {
            this.message = "Thank you for writing to us. We will get back to you soon."
          }

          this.formDirective.resetForm();
        }
        else {
          this.message = "Failed submitting your Query";
        }
        this._sharedService.closeSpinner();
    },
    error=>{
        this.message = "Failed submitting your Query";
        this._sharedService.closeSpinner();
    });
  }

}