<div class="container register">
    <div class="row">
        <div class="col-md-3 register-left">
            <img src="https://image.ibb.co/n7oTvU/logo_white.png" alt="" />

            <img src="../../assets/images/delivery-icon.png"
                style="filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(500%) hue-rotate(190deg);"
                alt="" />
            <h5>Welcome to Drug Quester.</h5>
            <input type="submit" name="" value="Login" (click)="goSignup()" /><br />
        </div>
        <div class="col-md-9 register-right">
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <h3 class="register-heading"> Please register here.</h3>

                    <div class="row register-form">

                        <form [formGroup]="registerForm" (ngSubmit)="signup()">
                            <div class="row m-2">
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="First name"
                                        formControlName="firstName" required aria-label="First name">
                                    <div *ngIf="registerForm.get('firstName').invalid && (registerForm.get('firstName').touched || submitted)"
                                        class="text-danger">
                                        First name is required.
                                    </div>
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Last name"
                                        formControlName="lastName" required aria-label="Last name">
                                    <div *ngIf="registerForm.get('lastName').invalid && (registerForm.get('lastName').touched || submitted)"
                                        class="text-danger">
                                        Last name is required.
                                    </div>
                                </div>
                            </div>
                            <!-- Email and Mobile Fields -->
                            <div class="row m-2">
                                <div class="col">
                                    <input type="email" class="form-control" placeholder="Enter Email"
                                        formControlName="email" required aria-label="Email">
                                    <div *ngIf="registerForm.get('email').invalid && (registerForm.get('email').touched || submitted)"
                                        class="text-danger">
                                        Valid email is required.
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="input-group">
                                        <select class="form-select" id="inputGroupSelect03" formControlName="country"
                                            [(ngModel)]="countrycode" required>
                                            <option value="">Select Country</option>
                                            <option *ngFor="let country of countries" [value]="country.code">{{
                                                country.name }}</option>
                                        </select>
                                        <input type="tel" class="form-control" placeholder="Enter Mobile Number"
                                            formControlName="mobile" required aria-label="Mobile Number">

                                    </div>

                                    <div *ngIf="registerForm.get('mobile').invalid && (registerForm.get('mobile').touched || submitted)"
                                        class="text-danger">
                                        Mobile number must be 10 digits.
                                    </div>

                                </div>
                            </div>
                            <!-- Password Fields -->
                            <div class="row m-2">
                                <div class="col">
                                    <input type="password" class="form-control" placeholder="Password"
                                        formControlName="password" required aria-label="Password">
                                    <div *ngIf="registerForm.get('password').invalid && (registerForm.get('password').touched || submitted)"
                                        class="text-danger">
                                        Password must be at least 8 characters long.
                                    </div>
                                </div>
                                <div class="col">
                                    <input type="password" class="form-control" placeholder="Confirm Password"
                                        formControlName="confirmPassword" required aria-label="Confirm Password">
                                    <div *ngIf="registerForm.errors?.mismatch && (registerForm.get('confirmPassword').touched || submitted)"
                                        class="text-danger">
                                        Passwords must match.
                                    </div>
                                </div>
                            </div>
                            <!-- Company Information -->
                            <div class="row m-2">
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Company"
                                        formControlName="company" required aria-label="Company">
                                    <div *ngIf="registerForm.get('company').invalid && (registerForm.get('company').touched || submitted)"
                                        class="text-danger">
                                        Company is required.
                                    </div>
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Position"
                                        formControlName="position" required aria-label="Position">
                                </div>
                                <div class="col">
                                    <input type="text" class="form-control" placeholder="Department"
                                        formControlName="department" aria-label="Department">
                                </div>
                            </div>


                            <div class="row m-2 text-end">
                                <button type="submit" class="btn btn-primary mt-3"
                                    [disabled]="registerForm.invalid">Register</button>
                            </div>
                            <h3 align="center" *ngIf="message == 'User already exists in the System. Try updating your password'">
                                This E-mail address is already registered with our database. Please login to your account using your user ID (E-mail address), select a subscription plan and proceed for payment to access the full database. In case of not able to login, please write to us at info@drugquester.com<br /><br />
                                <span align="center"><button class="btn btn-primary" (click)="goSignup()">Login</button></span>
                            </h3>
                            <h3 align="center" *ngIf="message == 'TRIAL_ACCOUNT'">
                                Trial Account Activation is subject to Admin Approval. We will notify once it is Approved.
                            </h3>
                            <h3 align="center" *ngIf="message == 'failure'">
                                Payment is not processed successfully. Please contact the Sales team for verification
                            </h3>
                            <h3 align="center" *ngIf="message == 'PAYMENT_PENDING'">
                                Your account is created successfully.<br /><br />
                                <span align="center"><button class="btn btn-primary" (click)="goSignup()">Login</button></span>
                            </h3>
                        </form>
                    </div>



                </div>
            </div>
        </div>
    </div>
    <br /><br />
    <!-- <div class="content-box" *ngIf="message != '' && message != 'PAYMENT_PENDING'">
        <h3 align="center" *ngIf="message == 'TRIAL_ACCOUNT'">
            Trial Account Activation is subject to Admin Approval. We will notify once it is Approved.
        </h3>
        <h3 align="center" *ngIf="message == 'success'">
            Thank you. Payment is successful. Reference ID: {{referenceId}}
            <br />
            <a routerLink='/signin' routerLinkActive='active'>Signin</a>
        </h3>
        <h3 align="center" *ngIf="message == 'failure'">
            Payment is not processed successfully. Please contact the Sales team for verification
        </h3>
    </div>
    <div class="content-box" *ngIf="message != '' && message == 'PAYMENT_PENDING'">
        <h3 align="center" *ngIf="message == 'PAYMENT_PENDING'">
            Your account is created successfully.<br /><br />
            <span align="center"><a href="#/signin">Log In</a></span>
        </h3>
        <br />
    </div> -->
    <!-- <div class="content-box" *ngIf="message != '' && message == 'User already exists in the System. Try updating your password'">
        
        <br />
    </div> -->
</div>