<div id="subscription" style="min-height: calc(100vh - 175px);">
    <br /><br />
    <div class="content-box">
        <h3 style="text-align:center">Subscription</h3>

        <div class="pricing-plan__container">
            <section class="pricing-plan" *ngFor="let details of SubscriptionPanelContents">

                <div class="pricing-plan__header">
                    <h1 class="prircing-plan__title">{{details.subscription_name}}</h1>
                    <h2 class="pricing-plan__summery">for those getting started</h2>
                </div>
                <div class="pricing-plan__description">
                    <ul class="pricing-plan__list">
                        <li class="pricing-plan__feature">Payment : {{details.paymant}}</li>
                        <li class="pricing-plan__feature">Data Export/Download : {{details.data_export_download}}</li>
                        <li class="pricing-plan__feature">No of users :{{details.no_of_user}} </li>
                    </ul>
                </div>
                <div class="pricing-plan__actions">
                    <p class="pricing-plan__cost">${{details.price}}</p>
                    <p class="pricing-plan__text">{{details.duration}}</p>
                    <div *ngIf="details.subscription_name === 'Multi User/Multi Year'" class="enterprise-inputs">
                        <input type="number" [(ngModel)]="details.no_of_user" placeholder="Users"
                            (input)="calculateEnterprisePrice(details)" />
                        <input type="number" [(ngModel)]="details.years" placeholder="Years"
                            (input)="calculateEnterprisePrice(details)" />
                    </div>
                    <p
                        *ngIf="details.subscription_name === 'Multi User/Multi Year' && details.no_of_user > 0 && details.years > 0">
                        Total Price After Discount: ${{ details.discountedPrice | number:'1.2-2' }}
                    </p>
                    <a (click)="selectSubscription(details)" class="pricing-plan__button">Subscriber</a>
                </div>
            </section>
        </div>
    </div>
    <br /><br />
</div>
<!-- <div id="subscription" style="min-height: calc(100vh - 175px);">
    <br /><br />
    <div class="content-box">
        <h3 style="text-align:center">Subscription</h3>

        <div class="pricing-plan__container">
            <section class="pricing-plan" *ngFor="let details of SubscriptionPanelContents">
                <div class="pricing-plan__header">
                    <h1 class="pricing-plan__title">{{details.subscription_name}}</h1>
                    <h2 class="pricing-plan__summary">for those getting started</h2>
                </div>
                <div class="pricing-plan__description">
                    <ul class="pricing-plan__list">
                        <li class="pricing-plan__feature">Payment : {{details.paymant}}</li>
                        <li class="pricing-plan__feature">Data Export/Download : {{details.data_export_download}}</li>
                        <li class="pricing-plan__feature">No of users : {{details.no_of_user}} </li>
                    </ul>
                </div>
                <div class="pricing-plan__actions">
                    <p class="pricing-plan__cost">${{details.price}}</p>
                    <p class="pricing-plan__text">{{details.duration}}</p>
                    
                    
                    <div *ngIf="details.subscription_name === 'Eneterprise'" class="enterprise-inputs">
                        <input type="number" [(ngModel)]="details.no_of_user" placeholder="Number of Users" (input)="calculateEnterprisePrice(details)" />
                        <input type="number" [(ngModel)]="details.years" placeholder="Number of Years" (input)="calculateEnterprisePrice(details)" />
                    </div>
                    <p *ngIf="details.subscription_name === 'Eneterprise'">Total Price After Discount: ${{details.discountedPrice}}</p>

                    <a (click)="selectSubscription(details)" class="pricing-plan__button">Subscribe</a>
                </div>
            </section>
        </div>
    </div>
    <br /><br />
</div> -->