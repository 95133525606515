<div id="userguide" style="min-height: calc(100vh - 175px);">
    <br/><br/>
    <div class="content-box">        
        <h3>User Guide</h3>
        <mat-accordion>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>What is Drug Quester?</b>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>
                    Drug Quester is a Pharmaceutical Intelligence Database for all stake holders in a pharmaceutical business. The database covers approved small molecule Products, Biologics, from more than 60 countries and DMF from more than 10 countries. It is one of the largest collections of DMF information in the world. 
                    <br/><br/>
                    It also covers key patents (both active and expired) patents for each product and its worldwide family; their expiry extension (SPC and PTE) from more than 40 countries. Likewise, the database covers more than 40 countries data exclusivity information helping its users to plan for better product portfolio. Our proprietary insights for each product help its users for efficient decision making.  
                    <br/><br/>
                    It is the only database in the world which provides quantitative formulary of more than 10000 drug products which provides its user an upper hand to reduce their product development time.
                    <br/><br/>
                    There are many more data and analytics like clinical trial coverage for more than 10 countries, pipeline products, drug price, sales trend, indication data analytics etc.
                </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>How to use It?</b>
                    </mat-panel-title>                
                </mat-expansion-panel-header>
                <p>
                    The database provides freedom to search and refine data by more than 500 parameters for deep digging into the data to get required output. It allows users to navigate effortlessly and focus only on what is essential. You can search, refine, analyse and then exporting the data in the form of intelligence.
                </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>How is the data made available to subscribers?</b>
                    </mat-panel-title>                
                </mat-expansion-panel-header>
                <p>
                    Drug Quester database is available over the internet. We aim to provide access to the database available every day, 24 hours a day. We have the site on a dedicated server, and the data is backed up daily. So, if there were ever a problem, an interruption of service might only be a few hours.
                </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>Can I get a free trial?</b>
                    </mat-panel-title>                
                </mat-expansion-panel-header>
                <p>
                    If you already have an account on our website, please log in to proceed.<br/><br/>
                    Request for a free trial to explore a scientific intelligence ride of a limited version & advanced features of our Informative & Innovative platform.
                    Looking forward to assist you & associate with your organization.
                </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>How do I subscribe?</b>
                    </mat-panel-title>                
                </mat-expansion-panel-header>
                <p>
                    Users can subscribe the database at the website. There is single user, multiuser, single year, multiyear subscription option. Please check the multiuser and multiyear plan for various discount availability. You can also ask for a quotation for your specific requirement.
                </p>
            </mat-expansion-panel>

            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <b>What are the Terms and Conditions for Subscription?</b>
                    </mat-panel-title>                
                </mat-expansion-panel-header>
                <p>Please read the 
                    <a routerLink="/tnc" routerLinkActive="active">Terms and Conditions</a> 
                    section carefully before subscription. </p>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>