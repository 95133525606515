<div id="subscribers" style="min-height: calc(100vh - 175px);">
    <br/><br/>
    <div class="content-box">        
        <h3>Subscribers</h3>
        <div style="text-align: justify;">
            Drug Quester is trusted globally by leading pharmaceutical & biopharmaceutical companies 
            and other stakeholders and institutional investors across the pharmaceutical industry.
        </div>
        <br/>
        <table align="center"><tr><td>
            <div class="subscribers-parent">
                <div class="subscriber-box">
                    Active-Ingredient Suppliers
                </div>
                <div class="subscriber-box">
                    Brand Manufacturers
                </div>
                <div class="subscriber-box">
                    Generic Manufacturers
                </div>
            </div>
            <div class="subscribers-parent">
                <div class="subscriber-box">
                    Pharma Expo-Impo Companies
                </div>
                <div class="subscriber-box">
                    Pharma Research Centers
                </div>
                <div class="subscriber-box">
                    Wholesalers & Distributors
                </div>
            </div>
            <div class="subscribers-parent">
                <div class="subscriber-box">
                    Investors & Financers
                </div>
                <div class="subscriber-box">
                    Retail & Specialty Pharmacies
                </div>
                <div class="subscriber-box">
                    Healthcare payers
                </div>
            </div>
        </td></tr></table>        
    </div>
</div>