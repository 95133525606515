import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { APP_CONFIG } from '../environment/Env';
import { HTTPService } from '../service/http-service';
import { SharedService } from '../service/shared-service';


@Component({
  selector: 'recovery',
  templateUrl: './recovery.component.html',
  styleUrls: [ './recovery.component.css' ]
})
export class RecoveryComponent {
  message: string = "";
  
  constructor(private formBuilder: FormBuilder, private _service: SharedService, private _http: HTTPService) {}

  recoveryForm: FormGroup = this.formBuilder.group({
      email: [,{validators: [Validators.required, Validators.email], updateOn: "change",}]
  });

  initiateRecovery() {
    this._service.openSpinner();
    let input = {};
    input["userId"] = this.recoveryForm.get("email").value;
    this._http.post(APP_CONFIG.adminAPIPath+"/public/recovery",input).subscribe(data=>{
      this.message = data["message"];
      this._service.closeSpinner();
    },
    error=>{
      this.message = "Failed initiating the account recovery service."
      this._service.closeSpinner();
    });
  }

}