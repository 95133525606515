import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SharedService } from './shared-service';

@Injectable()
export class HTTPService {

    constructor(private _http:HttpClient, private _sharedService: SharedService) {

    }

    getMetaData(url: string) {
        return this._http.get(url);
    }

    get(url: string, inputParams?:any[]) {
        let options = {};

        let headers:HttpHeaders = new HttpHeaders();
        
        let params:HttpParams = new HttpParams();

        if(inputParams != null) {
            inputParams.forEach( param => {
                params = params.append(param["key"],param["value"]);
            });
        }        

        options["headers"] = headers;
        options["params"] = params;
        
        return this._http.get(url,options);
    }

    getWithNoParam(url: string) {
        let options = {};

        let headers:HttpHeaders = new HttpHeaders();
        
        options["headers"] = headers;
        
        return this._http.get(url,options);
    }

    getObjectData(url: string, inputParams:any[]) {
        let options = {};

        let headers:HttpHeaders = new HttpHeaders();
        
        let params:HttpParams = new HttpParams();

        if(inputParams != null) {
            inputParams.forEach( param => {
                params = params.append(param["key"],param["value"]);
            });
        }        

        options["headers"] = headers;
        options["params"] = params;
        options["responseType"] = "blob";
        
        return this._http.get(url,options);
    }

    post(url: string, body: any) {
        let options = {};

        let headers:HttpHeaders = new HttpHeaders();
        
        options["headers"] = headers;

        return this._http.post(url,body,options);
    }

    delete(url: string, inputParams:any[]) {
        let options = {};

        let headers:HttpHeaders = new HttpHeaders();
        
        let params:HttpParams = new HttpParams();

        if(inputParams != null) {
            inputParams.forEach( param => {
                params = params.append(param["key"],param["value"]);
            });
        }        

        options["headers"] = headers;
        options["params"] = params;

        return this._http.delete(url,options);
    }
}